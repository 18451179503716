import { createReducer, on } from '@ngrx/store';
import { ForecastBaseTask, DemandsShaperTask } from '@/app/@core/backend/simcel/demand-generation.service';
import { 
  FETCH_PENDING_FORECAST_BASE_TASKS,
  FETCH_PENDING_FORECAST_BASE_TASKS_SUCCESS,
  FETCH_PENDING_FORECAST_BASE_TASKS_FAILED,
  FETCH_PENDING_DEMANDS_SHAPER_TASKS,
  FETCH_PENDING_DEMANDS_SHAPER_TASKS_SUCCESS,
  FETCH_PENDING_DEMANDS_SHAPER_TASKS_FAILED
} from './demand-generation.actions';

export const DEMAND_GENERATION_STATE_KEY = 'demand-generation';

/*************************************
 * State
 *************************************/

export interface IForecastBaseTaskState {
  pendingForecastBaseTasks: ForecastBaseTask[];
  pendingDemandsShaperTasks: DemandsShaperTask[];
  loading: boolean;
  errors: any;
}

export const initialState: IForecastBaseTaskState = {
  pendingForecastBaseTasks: [],
  pendingDemandsShaperTasks: [],
  loading: false,
  errors: [],
};

/*************************************
 * Reducers
 *************************************/
export const demandGenerationReducer = createReducer(
  initialState,
  on(FETCH_PENDING_FORECAST_BASE_TASKS, (state) => ({
      ...state,
      loading: true
  })),
  on(FETCH_PENDING_FORECAST_BASE_TASKS_SUCCESS, (state, { data }) => ({
    ...state,
    pendingForecastBaseTasks: data,
    loading: false
  })),
  on(FETCH_PENDING_FORECAST_BASE_TASKS_FAILED, (state, { error }) => ({
    ...state,
    pendingForecastBaseTasks: [],
    loading: false,
    errors: error
  })),
  on(FETCH_PENDING_DEMANDS_SHAPER_TASKS, (state) => ({
    ...state,
    loading: true
  })),
  on(FETCH_PENDING_DEMANDS_SHAPER_TASKS_SUCCESS, (state, { data }) => ({
    ...state,
    pendingDemandsShaperTasks: data,
    loading: false
  })),
  on(FETCH_PENDING_DEMANDS_SHAPER_TASKS_FAILED, (state, { error }) => ({
    ...state,
    pendingDemandsShaperTasks: [],
    loading: false,
    errors: error
  })),
);
