import { Workspace } from '../common/workspace';
import { Demand } from './demand';

/**
 * Defines a KPI breakdown, where each of the property is a measurement of
 * success for the supply chain network.
 *
 * This structure is used mostly as a receiver for aggregated KPI endpoints.
 * The `_id` property defines how the retrieved aggregated KPI endpoints are
 * grouped.
 *
 * The current implementation in the backend only groups by date, so the
 * format of the `_id` property looks like:
 *
 *  _id: {
 *    "day": "DD",
 *    "month": "MM",
 *    "year": "YYYY",
 *  }
 *
 * TODO(nathanielcapule): Consider renaming `_id` to `grouping` or flattening.
 */
export declare interface Breakdown {
  /** Indicator of how the retrieved KPIs are grouped before being aggregated. */
  _id?: { [key: string]: any };

  /** @deprecated This field will not be return in the new KPI endpoint */
  id: string;
  demandValue: number;
  netSalesValue: number;
  grossSalesValue: number;
  tradeExpenses: number;
  cogs: number;
  transferCost: number;
  storageCost: number;
  handlingCost: number;
  distributionCost: number;
  DistributorCOGS: number;
  DistributorDistributionCost: number;
  DistributorTransferCost: number;
  DistributorStorageCost: number;
  DistributorHandlingCost: number;
  DistributorNetSalesValue: number;
  dispatchQuantity: number;
  orderQuantity: number;
  averageInventoryValue: number;
  count?: number;
  CO2?: number;
  InboundCO2?: number;
  StorageCO2?: number;
  OutboundCO2?: number;
  UnconstrainedCLS?: number;
  UnconstrainedSalesOut?: number;
  ConstrainedCLS?: number;
  ConstrainedSalesOut?: number;
  UnconstrainedAverageSalesOutGSV: number;
  ConstrainedAverageSalesOutGSV: number;
  UnconstrainedCLSGSV: number;
  ConstrainedCLSGSV: number;
  ConstrainedSalesOutGSV: number;
  UnconstrainedSalesOutGSV: number;
  CostMaster: number;
  FIEDemandValue: number;
  GrossFIESales: number;
  FIETradeExpenses: number;
  Transport: number;
  StorageAndHandling: number;
  Promotion: number;
  MedicalAffairs: number;
  Administration: number;
  LocalDemandValue: number;
  LocalDemandVolume: number;
  GrossLocalSales: number;
  LocalSalesVolume: number;
  DistributorLocalDemandValue: number;
  DistributorLocalDemandVolume: number;
  DistributorGrossLocalSales: number;
  DistributorLocalSalesVolume: number;
  LocalTradeExpenses: number;
  FIEDemandVolume: number;
  FIESalesVolume: number;
}

/** Returns a zero-valued breakdown. */
export function emptyBreakdown(): Breakdown {
  return {
    id: '',
    demandValue: 0,
    netSalesValue: 0,
    grossSalesValue: 0,
    tradeExpenses: 0,
    cogs: 0,
    transferCost: 0,
    storageCost: 0,
    handlingCost: 0,
    distributionCost: 0,
    DistributorCOGS: 0,
    DistributorDistributionCost: 0,
    DistributorTransferCost: 0,
    DistributorStorageCost: 0,
    DistributorHandlingCost: 0,
    DistributorNetSalesValue: 0,
    orderQuantity: 0,
    dispatchQuantity: 0,
    averageInventoryValue: 0,
    CO2: 0,
    InboundCO2: 0,
    StorageCO2: 0,
    OutboundCO2: 0,
    count: 0,
    UnconstrainedCLS: 0,
    UnconstrainedSalesOut: 0,
    ConstrainedCLS: 0,
    ConstrainedSalesOut: 0,
    UnconstrainedAverageSalesOutGSV: 0,
    ConstrainedAverageSalesOutGSV: 0,
    UnconstrainedCLSGSV: 0,
    ConstrainedCLSGSV: 0,
    ConstrainedSalesOutGSV: 0,
    UnconstrainedSalesOutGSV: 0,
    CostMaster: 0,
    FIEDemandValue: 0,
    GrossFIESales: 0,
    FIETradeExpenses: 0,
    Transport: 0,
    StorageAndHandling: 0,
    Promotion: 0,
    MedicalAffairs: 0,
    Administration: 0,
    LocalDemandValue: 0,
    LocalDemandVolume: 0,
    GrossLocalSales: 0,
    LocalSalesVolume: 0,
    DistributorLocalDemandValue: 0,
    DistributorLocalDemandVolume: 0,
    DistributorGrossLocalSales: 0,
    DistributorLocalSalesVolume: 0,
    LocalTradeExpenses: 0,
    FIEDemandVolume: 0,
    FIESalesVolume: 0,
  };
}

/** Merge together a given list of breakdowns' KPI stats. */
export function mergeBreakdowns(...breakdowns: Breakdown[]) {
  // Base KPI.
  const base = emptyBreakdown();
  // List of property names that corresponds to a KPI. It excludes 'id' of course.
  const kpiProps = Object.keys(base).filter((prop) => prop !== 'id') as (keyof Breakdown)[];

  // Sum all the KPI properties of the listed breakdowns.
  return breakdowns.reduce((prev, curr) => {
    kpiProps.forEach((prop) => ((prev[prop] as number) += +(curr?.[prop] || 0)));
    return prev;
  }, base);
}

export function calculateNetFIESales(breakdown?: Breakdown | null): number | null {
  if (!breakdown) return null;
  return (breakdown.GrossFIESales || 0) - (breakdown.FIETradeExpenses || 0);
}

export function calculateGrossProfit(breakdown?: Breakdown | null): number | null {
  if (!breakdown) return null;
  return (breakdown.GrossFIESales || 0) - (breakdown.FIETradeExpenses || 0) - breakdown.cogs;
}

export function calculateNetLocalSales(breakdown?: Breakdown | null): number | null {
  if (!breakdown) return null;
  return (breakdown.GrossLocalSales || 0) - (breakdown.LocalTradeExpenses || 0);
}

export function calculateCOGSPercent(breakdown?: Breakdown | null): number | null {
  if (!breakdown) return null;
  const netFIESales = calculateNetFIESales(breakdown);
  return (breakdown.cogs / (netFIESales || 0)) * 100;
}

export function calculateGrossMargin(breakdown?: Breakdown | null): number | null {
  if (!breakdown) return null;
  const grossProfit = calculateGrossProfit(breakdown);
  const netFIESales = calculateNetFIESales(breakdown);
  return ((grossProfit || 0) / (netFIESales || 0)) * 100;
}

export function calculateSupplyChainCost(breakdown?: Breakdown | null): number | null {
  if (!breakdown) return null;
  return (breakdown.Transport || 0) + (breakdown.StorageAndHandling || 0);
}

export function calculateDPM(breakdown?: Breakdown | null): number | null {
  if (!breakdown) return null;
  const supplyChainCost = calculateSupplyChainCost(breakdown);
  return (supplyChainCost || 0) + (breakdown.Promotion || 0) + (breakdown.MedicalAffairs || 0);
}

export function calculateDPMPercent(breakdown?: Breakdown | null): number | null {
  if (!breakdown) return null;
  const dpm = calculateDPM(breakdown);
  const netFIESales = calculateNetFIESales(breakdown);
  return ((dpm || 0) / (netFIESales || 0)) * 100;
}

export function calculateDPMA(breakdown?: Breakdown | null): number | null {
  if (!breakdown) return null;
  const dpm = calculateDPM(breakdown);
  return (dpm || 0) + (breakdown.Administration || 0);
}

export function calculateDPMAPercent(breakdown?: Breakdown | null): number | null {
  if (!breakdown) return null;
  const dpma = calculateDPMA(breakdown);
  const netFIESales = calculateNetFIESales(breakdown);
  return ((dpma || 0) / (netFIESales || 0)) * 100;
}

export function calculateEBITPercent(breakdown?: Breakdown | null): number | null {
  if (!breakdown) return null;
  const ebit = calculateEBIT(breakdown);
  const netFIESales = calculateNetFIESales(breakdown);
  return ((ebit || 0) / (netFIESales || 0)) * 100;
}

export function calculateLocalCaseFillRate(breakdown?: Breakdown | null): number | null {
  if (!breakdown) return null;
  return ((breakdown.LocalSalesVolume || 0) / (breakdown.LocalDemandVolume || 0)) * 100;
}

export function calculateFIECaseFillRate(breakdown?: Breakdown | null): number | null {
  if (!breakdown) return null;
  return ((breakdown.FIESalesVolume || 0) / (breakdown.FIEDemandVolume || 0)) * 100;
}

/** Utility function for calculating storage and handling cost from a KPI breakdown. */
export function calculateStorageAndHandling(breakdown?: Breakdown | null): number | null {
  if (!breakdown) return null;
  return breakdown.DistributorStorageCost + breakdown.DistributorHandlingCost;
}

export function calculateStorageAndDistribution(breakdown?: Breakdown | null): number | null {
  if (!breakdown) return null;
  return (
    breakdown.DistributorTransferCost +
    breakdown.DistributorDistributionCost +
    breakdown.DistributorStorageCost +
    breakdown.DistributorHandlingCost
  );
}

/** Utility function for calculating CBM of a KPI breakdown. */
export function calculateCbm(breakdown?: Breakdown | null): number | null {
  if (!breakdown) return null;
  const storage = calculateStorageAndHandling(breakdown);
  return (
    breakdown.DistributorNetSalesValue -
    breakdown.DistributorCOGS -
    breakdown.DistributorTransferCost -
    breakdown.DistributorDistributionCost -
    (storage || 0)
  );
}

export function calculateEBIT(breakdown?: Breakdown | null): number | null {
  if (!breakdown) return null;
  const grossProfit = calculateGrossProfit(breakdown);
  return (
    (grossProfit || 0) -
    breakdown.Transport -
    breakdown.StorageAndHandling -
    breakdown.Promotion -
    breakdown.MedicalAffairs -
    breakdown.Administration
  );
}

export function calculateServiceLevel(breakdown?: Breakdown | null): number | null {
  if (!breakdown) return null;
  return (breakdown.dispatchQuantity / breakdown.orderQuantity) * 100;
}

export function calculateMargin(breakdown?: Breakdown | null): number | null {
  const cbm = calculateCbm(breakdown);
  if (!breakdown || cbm === null) return null;
  return (cbm / breakdown.DistributorNetSalesValue) * 100;
}

export function calculateUnconstrainedSIT(breakdown?: Breakdown | null): number | null {
  if (!breakdown) return null;
  return (breakdown.UnconstrainedCLSGSV / breakdown.UnconstrainedAverageSalesOutGSV);
}

export function calculateConstrainedSIT(breakdown?: Breakdown | null): number | null {
  if (!breakdown) return null;
  return (breakdown.ConstrainedCLSGSV / breakdown.ConstrainedAverageSalesOutGSV);
}

/**
 * Utility function for calculating timestamp from _id property of a KPI breakdown.
 *
 * The name of the `_id` property comes from the hardcoded column name needed by
 * MongoDB when wanting to group an aggregation (ie. GROUP BY in SQL), which is
 * why we reuse this convention in the frontend.
 */
export function calculateTimestamp(breakdown: Breakdown | Demand): number {
  const grouping = breakdown._id;
  if (!grouping) {
    return 0;
  }
  const year = grouping['year'] || '0000';
  const month = grouping['month'] || '00';
  const day = grouping['day'] || '00';

  return new Date(`${year}-${month}-${day}`).getTime();
}

// Todo : refactor to use enum for all possible types of KPIs
export type BreakdownKpis =
  | keyof Breakdown
  | 'cbm'
  | 'serviceLevel'
  | 'margin'
  | 'storageAndDistribution'
  | 'ConstrainedSIT'
  | 'UnconstrainedSIT'
  | 'EBIT'
  | 'NetFIESales'
  | 'GrossProfit'
  | 'NetLocalSales'
  | 'COGSPercent'
  | 'GrossMargin'
  | 'SupplyChainCost'
  | 'DPM'
  | 'DPMPercent'
  | 'DPMA'
  | 'DPMAPercent'
  | 'EBITPercent'
  | 'LocalCaseFillRate'
  | 'FIECaseFillRate'
  ;
export interface KpiMetadata {
  shortName: BreakdownKpis;
  name: string;
  type: 'profit' | 'lost';
  measureIn: 'value' | 'percentage';
  getter?: (b?: Breakdown | null) => number | null;
}

export interface DimensionGroup {
  name: string;
  children: {
    name: string;
    label: string;
  }[];
};

export const KPI_UNIT_LABEL = [
  { unit: "Carton", label: "CTN" },
  { unit: "Kg", label: "KG" },
  { unit: "Unit", label: "UNIT" },
  { unit: "USD", label: "USD" },
  { unit: "VND", label: "VND" },
  { unit: "EUR", label: "EUR" },
]

/** List of KPI metadatas with quantity unit */
export const QUANTITY_KPIS = [
  'OrderQuantity',
  'DispatchQuantity',
  'CO2',
  'InboundCO2',
  'StorageCO2',
  'OutboundCO2',
  'UnconstrainedCLS',
  'UnconstrainedSalesOut',
  'ConstrainedCLS',
  'ConstrainedSalesOut',
  'LocalDemandVolume',
  'LocalSalesVolume',
  'DistributorLocalDemandVolume',
  'DistributorLocalSalesVolume',
  'FIEDemandVolume',
  'FIESalesVolume',
];

/** List of KPI metadatas with currency */
export const CURRENCY_KPIS = [
  'DemandValue',
  'GrossSalesValue',
  'NetSalesValue',
  'TradeExpenses',
  'DistributionCost',
  'COGS',
  'TransferCost',
  'StorageCost',
  'HandlingCost',
  'DistributorCOGS',
  'DistributorDistributionCost',
  'DistributorTransferCost',
  'DistributorStorageCost',
  'DistributorHandlingCost',
  'DistributorNetSalesValue',
  'StorageAndDistribution',
  'ProfitBeforeMarketing',
  'ConstrainedSalesOutGSV',
  'UnconstrainedSalesOutGSV',
  'UnconstrainedCLSGSV',
  'ConstrainedCLSGSV',
  'ConstrainedAverageSalesOutGSV',
  'UnconstrainedAverageSalesOutGSV',
  'CostMaster',
  'EBIT',
  'FIEDemandValue',
  'GrossFIESales',
  'FIETradeExpenses',
  'Transport',
  'StorageAndHandling',
  'Promotion',
  'MedicalAffairs',
  'Administration',
  'NetFIESales',
  'GrossProfit',
  'LocalDemandValue',
  'GrossLocalSales',
  'DistributorLocalDemandValue',
  'DistributorGrossLocalSales',
  'LocalTradeExpenses',
  'NetLocalSales',
  'SupplyChainCost',
  'DPM',
  'DPMA',
]

/** List of KPI metadatas with percentage */
export const PERCENTAGE_KPIS = [
  'Margin',
  'ServiceLevel',
  'COGSPercent',
  'GrossMargin',
  'DPMPercent',
  'DPMAPercent',
  'EBITPercent',
  'LocalCaseFillRate',
  'FIECaseFillRate',
]

export const DAYS_KPIS = [
  'UnconstrainedSIT',
  'ConstrainedSIT'
]

/** List of KPI metadatas. */

export const ATOMIC_KPIS: Array<{ name: string, label: string }> = [
  { name: 'FIEDemandValue', label: 'Primary Demand Value' },
  { name: 'FIEDemandVolume', label: 'Primary Demand Volume' },
  { name: 'FIESalesVolume', label: 'Primary Sales Volume' },
  { name: 'GrossFIESales', label: 'Primary Gross Sales Value' },
  { name: 'NetFIESales', label: 'Primary Net Sales Value' },
  { name: 'FIETradeExpenses', label: 'Primary Trade Expenses' },
  // { name: 'DistributionCost', label: 'Distribution Cost' },
  // { name: 'COGS', label: 'COGS' },
  // { name: 'TransferCost', label: 'Stock Transfer' },
  // { name: 'StorageCost', label: 'Storage' },
  // { name: 'HandlingCost', label: 'Handling Cost' },
  { name: 'DistributorCOGS', label: 'COGS' },
  { name: 'DistributorDistributionCost', label: 'Distribution Cost' },
  { name: 'DistributorTransferCost', label: 'Stock Transfer' },
  { name: 'DistributorStorageCost', label: 'Storage' },
  { name: 'DistributorHandlingCost', label: 'Handling Cost' },
  // { name: 'DistributorNetSalesValue', label: 'Net Sales Value' },
  { name: 'CO2', label: 'CO2 Emission' },
  { name: 'InboundCO2', label: 'Inbound CO2 Emission' },
  { name: 'StorageCO2', label: 'Storage CO2 Emission' },
  { name: 'OutboundCO2', label: 'Outbound CO2 Emission' },
  { name: 'StorageAndDistribution', label: 'Storage and Distribution' },
  { name: 'ProfitBeforeMarketing', label: 'Profit Before Marketing' },
  { name: 'Margin', label: 'Margin' },
  { name: 'StorageAndHandling', label: 'Storage and Handling' },
  { name: 'FIECaseFillRate', label: 'Primary Case Fill Rate' },
  { name: 'LocalCaseFillRate', label: 'Secondary Case Fill Rate' },
  { name: 'UnconstrainedSIT', label: 'Unconstrained SIT' },
  { name: 'UnconstrainedCLS', label: 'Unconstrained Distributor Closing Stock Volume' },
  { name: 'ConstrainedSIT', label: 'Constrained SIT' },
  { name: 'ConstrainedCLS', label: 'Constrained Distributor Closing Stock Volume' },
  { name: 'UnconstrainedCLSGSV', label: 'Unconstrained Distributor Closing Stock Value' },
  { name: 'ConstrainedCLSGSV', label: 'Constrained Distributor Closing Stock Value' },
  { name: 'LocalDemandValue', label: 'Secondary Demand Value' },
  { name: 'LocalDemandVolume', label: 'Secondary Demand Volume' },
  { name: 'GrossLocalSales', label: 'Secondary Gross Sales' },
  { name: 'LocalSalesVolume', label: 'Secondary Sales Volume' },
  { name: 'LocalTradeExpenses', label: 'Secondary Trade Expenses' },
  { name: 'NetLocalSales', label: 'Secondary Net Sales' },
  { name: 'DistributorLocalDemandValue', label: 'Distributor Secondary Gross Demand Value' },
  { name: 'DistributorLocalDemandVolume', label: 'Distributor Secondary Demand Volume' },
  { name: 'DistributorGrossLocalSales', label: 'Distributor Secondary Gross Sales Value' },
  { name: 'DistributorLocalSalesVolume', label: 'Distributor Secondary Sales Volume' },
];

export const FINANCE_ATOMIC_KPIS: Array<{ name: string, label: string }> = [
  { name: 'Transport', label: 'Transport' },
  { name: 'Promotion', label: 'Promotion' },
  { name: 'MedicalAffairs', label: 'Medical Affairs' },
  { name: 'Administration', label: 'Administration' },
  { name: 'GrossProfit', label: 'Gross Profit' },
  { name: 'COGSPercent', label: 'COGS as % of Primary NSV' },
  { name: 'GrossMargin', label: 'Gross Margin' },
  { name: 'SupplyChainCost', label: 'Supply Chain Cost' },
  { name: 'DPM', label: 'D-P-M' },
  { name: 'DPMPercent', label: 'D-P-M as % of Primary NSV' },
  { name: 'DPMA', label: 'D-P-M-A' },
  { name: 'DPMAPercent', label: 'D-P-M-A as % of Primary NSV' },
  // { name: 'CostMaster', label: 'Cost Master' }, // hide CostMaster in Business Explorer
  { name: 'EBIT', label: 'EBIT' },
  { name: 'EBITPercent', label: 'EBIT as % of Primary NSV' },
];

export const SANOFI_DIMENSION_GROUPS: Array<DimensionGroup> = [
  {
    name: 'Product',
    children: [
      { name: 'ProductID', label: 'Product ID' },
    ]
  },
  {
    name: 'Customer',
    children: [
      { name: 'Channel', label: 'Channel' },
    ]
  },
  {
    name: 'Location',
    children: [
      { name: 'Region', label: 'Region' },
    ]
  }
];

export const DIMENSION_GROUPS: Array<DimensionGroup> = [
  {
    name: 'Product',
    children: [
      { name: 'Category', label: 'Category' },
      { name: 'SubCategory', label: 'Sub Category' },
      { name: 'Brands', label: 'Brands' },
      { name: 'ProductRange', label: 'Product Range' },
      { name: 'ProductName', label: 'Product Name' },
      { name: 'ProductType', label: 'Product Type' },
      { name: 'ProductID', label: 'Product ID' },
    ]
  },
  {
    name: 'Customer',
    children: [
      { name: 'Channel', label: 'Channel' },
      { name: 'SubChannel', label: 'Sub Channel' },
      { name: 'KeyAccount', label: 'Key Account' },
      { name: 'CustomerRef', label: 'Customer Ref' },
      { name: 'Name', label: 'Customer Name' },
    ]
  },
  {
    name: 'Location',
    children: [
      { name: 'Country', label: 'Country' },
      { name: 'Region', label: 'Region' },
      { name: 'City', label: 'City' },
      { name: 'ShipTo', label: 'Ship To' },
    ]
  }
];

export function filterDimensionGroups(groups: Array<DimensionGroup>, workspace: Workspace | undefined) {
  const hiddenMasterDataFieldNames = workspace?.settings?.hiddenMasterDataFieldNames || [];
  return groups.map(group => ({ ...group, children: group.children.filter(child => !hiddenMasterDataFieldNames.includes(child.label)) }));
}

/** List of KPI metadatas with quantity unit */
export const QUANTITY_BREAKDOWN_KPIS = [
  'orderQuantity',
  'dispatchQuantity',
  'CO2',
]

/** List of KPI metadatas with currency */
export const CURRENCY_BREAKDOWN_KPIS = [
  'demandValue',
  'grossSalesValue',
  'tradeExpenses',
  'distributionCost',
  'netSalesValue',
  'cogs',
  'transferCost',
  'storageCost',
  'handlingCost',
  'storageAndDistribution',
  'cbm',
  'averageInventoryValue',
  'CostMaster',
  'EBIT',
  'FIEDemandValue',
  'GrossFIESales',
  'FIETradeExpenses',
  'Transport',
  'StorageAndHandling',
  'Promotion',
  'MedicalAffairs',
  'Administration',
  'NetFIESales',
  'GrossProfit',
  'LocalDemandValue',
  'GrossLocalSales',
  'LocalTradeExpenses',
  'NetLocalSales',
  'SupplyChainCost',
  'DPM',
  'DPMA',
]

export const ATOMIC_KPIS_TO_BREAKDOWN_MAP = {
  DemandValue: 'demandValue',
  OrderQuantity: 'orderQuantity',
  DispatchQuantity: 'dispatchQuantity',
  GrossSalesValue: 'grossSalesValue',
  NetSalesValue: 'netSalesValue',
  TradeExpenses: 'tradeExpenses',
  DistributionCost: 'distributionCost',
  COGS: 'cogs',
  TransferCost: 'transferCost',
  StorageCost: 'storageCost',
  HandlingCost: 'handlingCost',
  DistributorCOGS: 'DistributorCOGS',
  DistributorDistributionCost: 'DistributorDistributionCost',
  DistributorTransferCost: 'DistributorTransferCost',
  DistributorStorageCost: 'DistributorStorageCost',
  DistributorHandlingCost: 'DistributorHandlingCost',
  DistributorNetSalesValue: 'DistributorNetSalesValue',
  CO2: 'CO2',
  InboundCO2: 'InboundCO2',
  StorageCO2: 'StorageCO2',
  OutboundCO2: 'OutboundCO2',
  StorageAndDistribution: 'storageAndDistribution',
  ProfitBeforeMarketing: 'cbm',
  Margin: 'margin',
  ServiceLevel: 'serviceLevel',
  UnconstrainedSIT: 'UnconstrainedSIT',
  UnconstrainedCLS: 'UnconstrainedCLS',
  UnconstrainedSalesOut: 'UnconstrainedSalesOut',
  ConstrainedSIT: 'ConstrainedSIT',
  ConstrainedCLS: 'ConstrainedCLS',
  ConstrainedSalesOut: 'ConstrainedSalesOut',
  ConstrainedSalesOutGSV: 'ConstrainedSalesOutGSV',
  UnconstrainedSalesOutGSV: 'UnconstrainedSalesOutGSV',
  UnconstrainedCLSGSV: 'UnconstrainedCLSGSV',
  ConstrainedCLSGSV: 'ConstrainedCLSGSV',
  CostMaster: 'CostMaster',
  EBIT: 'EBIT',
  FIEDemandValue: 'FIEDemandValue',
  GrossFIESales: 'GrossFIESales',
  FIETradeExpenses: 'FIETradeExpenses',
  Transport: 'Transport',
  StorageAndHandling: 'StorageAndHandling',
  Promotion: 'Promotion',
  MedicalAffairs: 'MedicalAffairs',
  Administration: 'Administration',
  NetFIESales: 'NetFIESales',
  GrossProfit: 'GrossProfit',
  LocalDemandValue: 'LocalDemandValue',
  LocalDemandVolume: 'LocalDemandVolume',
  GrossLocalSales: 'GrossLocalSales',
  LocalSalesVolume: 'LocalSalesVolume',
  DistributorLocalDemandValue: 'DistributorLocalDemandValue',
  DistributorLocalDemandVolume: 'DistributorLocalDemandVolume',
  DistributorGrossLocalSales: 'DistributorGrossLocalSales',
  DistributorLocalSalesVolume: 'DistributorLocalSalesVolume',
  LocalTradeExpenses: 'LocalTradeExpenses',
  FIEDemandVolume: 'FIEDemandVolume',
  FIESalesVolume: 'FIESalesVolume',
  LocalCaseFillRate: 'LocalCaseFillRate',
  FIECaseFillRate: 'FIECaseFillRate',
  NetLocalSales: 'NetLocalSales',
  COGSPercent: 'COGSPercent',
  GrossMargin: 'GrossMargin',
  SupplyChainCost: 'SupplyChainCost',
  DPM: 'DPM',
  DPMPercent: 'DPMPercent',
  DPMA: 'DPMA',
  DPMAPercent: 'DPMAPercent',
  EBITPercent: 'EBITPercent',
};

/** List of KPI metadatas with percentage */
export const PERCENTAGE_BREAKDOWN_KPIS = [
  'margin',
  'serviceLevel',
  'COGSPercent',
  'GrossMargin',
  'DPMPercent',
  'DPMAPercent',
  'EBITPercent',
  'LocalCaseFillRate',
  'FIECaseFillRate',
]

// change Type readony to any : because change list sort
export const BREAKDOWN_KPIS_INFO_LIST: Array<KpiMetadata> = [
  {
    shortName: 'FIEDemandValue',
    name: 'Primary\nDemand\nValue',
    type: 'profit',
    measureIn: 'value',
  },
  {
    shortName: 'FIEDemandVolume',
    name: 'Primary Demand Volume',
    type: 'profit',
    measureIn: 'value',
  },
  {
    shortName: 'FIESalesVolume',
    name: 'Primary Sales Volume',
    type: 'profit',
    measureIn: 'value',
  },
  {
    shortName: 'GrossFIESales',
    name: 'Primary\nGross\nSales\nValue',
    type: 'profit',
    measureIn: 'value',
  },
  {
    shortName: 'FIETradeExpenses',
    name: 'Primary\nTrade\nExpenses',
    type: 'lost',
    measureIn: 'value',
  },
  // {
  //   shortName: 'distributionCost',
  //   name: 'Distribution\nCost',
  //   type: 'lost',
  //   measureIn: 'value',
  // },
  {
    shortName: 'NetFIESales',
    name: 'Primary\nNet\nSales\nValue',
    type: 'profit',
    getter: calculateNetFIESales,
    measureIn: 'value',
  },
  // { shortName: 'cogs', name: 'COGS', type: 'lost', measureIn: 'value' },
  // { shortName: 'transferCost', name: 'Stock\nTransfer', type: 'lost', measureIn: 'value' },
  // { shortName: 'storageCost', name: 'Storage', type: 'lost', measureIn: 'value' },
  // { shortName: 'handlingCost', name: 'Customer\nDelivery', type: 'lost', measureIn: 'value' },
  { shortName: 'DistributorCOGS', name: 'COGS', type: 'lost', measureIn: 'value' },
  {
    shortName: 'DistributorDistributionCost',
    name: 'Distribution\nCost',
    type: 'lost',
    measureIn: 'value',
  },
  { shortName: 'DistributorTransferCost', name: 'Stock\nTransfer', type: 'lost', measureIn: 'value' },
  { shortName: 'DistributorStorageCost', name: 'Storage', type: 'lost', measureIn: 'value' },
  { shortName: 'DistributorHandlingCost', name: 'Customer\nDelivery', type: 'lost', measureIn: 'value' },
  // {
  //   shortName: 'DistributorNetSalesValue',
  //   name: 'Dis\nNet\nSales\nValue',
  //   type: 'lost',
  //   measureIn: 'value',
  // },
  {
    shortName: 'storageAndDistribution',
    name: 'Storage\nAnd\nDistribution',
    type: 'lost',
    getter: calculateStorageAndDistribution,
    measureIn: 'value',
  },
  {
    shortName: 'cbm',
    name: 'Profit Before Marketing',
    type: 'profit',
    getter: calculateCbm,
    measureIn: 'value',
  },
  {
    shortName: 'margin',
    name: 'Margin',
    type: 'profit',
    measureIn: 'percentage',
    getter: calculateMargin,
  },
  {
    shortName: 'FIECaseFillRate',
    name: 'Primary Case Fill Rate',
    type: 'profit',
    getter: calculateFIECaseFillRate,
    measureIn: 'percentage',
  },
  {
    shortName: 'LocalCaseFillRate',
    name: 'Secondary Case Fill Rate',
    type: 'profit',
    getter: calculateLocalCaseFillRate,
    measureIn: 'percentage',
  },
  {
    shortName: 'averageInventoryValue',
    name: 'Average\nInventory\nValue',
    type: 'lost',
    measureIn: 'value',
  },
  {
    shortName: 'CO2',
    name: 'CO2\nEmission',
    type: 'lost',
    measureIn: 'value',
  },
  {
    shortName: 'InboundCO2',
    name: 'Inbound\nCO2\nEmission',
    type: 'lost',
    measureIn: 'value',
  },
  {
    shortName: 'StorageCO2',
    name: 'Storage\nCO2\nEmission',
    type: 'lost',
    measureIn: 'value',
  },
  {
    shortName: 'OutboundCO2',
    name: 'Outbound\nCO2\nEmission',
    type: 'lost',
    measureIn: 'value',
  },
  {
    shortName: 'UnconstrainedSIT',
    name: 'Unconstrained\nSIT',
    type: 'lost',
    getter: calculateUnconstrainedSIT,
    measureIn: 'value',
  },
  {
    shortName: 'UnconstrainedCLS',
    name: 'Unconstrained\nDistributor\nClosing\nStock\nVolume',
    type: 'lost',
    measureIn: 'value',
  },
  {
    shortName: 'DistributorLocalDemandVolume',
    name: 'Distributor\nSecondary\nDemand\nVolume',
    type: 'lost',
    measureIn: 'value',
  },
  {
    shortName: 'ConstrainedSIT',
    name: 'Constrained\nSIT',
    type: 'lost',
    getter: calculateConstrainedSIT,
    measureIn: 'value',
  },
  {
    shortName: 'ConstrainedCLS',
    name: 'Constrained\nDistributor\nClosing\nStock\nVolume',
    type: 'lost',
    measureIn: 'value',
  },
  {
    shortName: 'DistributorLocalSalesVolume',
    name: 'Distributor\nSecondary\nSales\nVolume',
    type: 'lost',
    measureIn: 'value',
  },
  {
    shortName: 'DistributorGrossLocalSales',
    name: 'Distributor\nSecondary\nGross\nSales\nValue',
    type: 'lost',
    measureIn: 'value',
  },
  {
    shortName: 'DistributorLocalDemandValue',
    name: 'Distributor\nSecondary\nGross\nDemand\nValue',
    type: 'lost',
    measureIn: 'value',
  },
  {
    shortName: 'UnconstrainedCLSGSV',
    name: 'Unconstrained\nDistributor\nClosing\nStock\nValue',
    type: 'lost',
    measureIn: 'value',
  },
  {
    shortName: 'ConstrainedCLSGSV',
    name: 'Constrained\nDistributor\nClosing\nStock\nValue',
    type: 'lost',
    measureIn: 'value',
  },
  // hide CostMaster in PnL chart
  // {
  //   shortName: 'CostMaster',
  //   name: 'Cost Master',
  //   type: 'lost',
  //   measureIn: 'value',
  // },
  {
    shortName: 'EBIT',
    name: 'EBIT',
    type: 'profit',
    getter: calculateEBIT,
    measureIn: 'value',
  }, 
  {
    shortName: 'LocalDemandValue',
    name: 'Secondary Demand Value',
    type: 'profit',
    measureIn: 'value',
  },
  {
    shortName: 'LocalDemandVolume',
    name: 'Secondary Demand Volume',
    type: 'profit',
    measureIn: 'value',
  },
  {
    shortName: 'GrossLocalSales',
    name: 'Secondary Gross Sales',
    type: 'profit',
    measureIn: 'value',
  },
  {
    shortName: 'LocalSalesVolume',
    name: 'Secondary Sales Volume',
    type: 'profit',
    measureIn: 'value',
  },
  {
    shortName: 'LocalTradeExpenses',
    name: 'Secondary Trade Expenses',
    type: 'lost',
    measureIn: 'value',
  },
  {
    shortName: 'NetLocalSales',
    name: 'Secondary\nNet\nSales',
    type: 'profit',
    getter: calculateNetLocalSales,
    measureIn: 'value',
  },
];

export const BREAKDOWN_FINANCE_KPIS_INFO_LIST: Array<KpiMetadata> = [
  {
    shortName: 'GrossProfit',
    name: 'Gross\nProfit',
    type: 'profit',
    getter: calculateGrossProfit,
    measureIn: 'value',
  },
  {
    shortName: 'Transport',
    name: 'Transport',
    type: 'lost',
    measureIn: 'value',
  },
  {
    shortName: 'StorageAndHandling',
    name: 'Storage and Handling',
    type: 'lost',
    measureIn: 'value',
  },
  {
    shortName: 'Promotion',
    name: 'Promotion',
    type: 'lost',
    measureIn: 'value',
  },
  {
    shortName: 'MedicalAffairs',
    name: 'Medical Affairs',
    type: 'lost',
    measureIn: 'value',
  },
  {
    shortName: 'Administration',
    name: 'Administration',
    type: 'lost',
    measureIn: 'value',
  },
  {
    shortName: 'COGSPercent',
    name: 'COGS\nas\n%\nof\nPrimary',
    type: 'lost',
    getter: calculateCOGSPercent,
    measureIn: 'percentage',
  },
  {
    shortName: 'GrossMargin',
    name: 'Gross\nMargin',
    type: 'profit',
    getter: calculateGrossMargin,
    measureIn: 'percentage',
  },
  {
    shortName: 'SupplyChainCost',
    name: 'Supply\nChain\nCost',
    type: 'lost',
    getter: calculateSupplyChainCost,
    measureIn: 'value',
  },
  {
    shortName: 'DPM',
    name: 'D-P-M',
    type: 'lost',
    getter: calculateDPM,
    measureIn: 'value',
  },
  {
    shortName: 'DPMPercent',
    name: 'D-P-M\nas\n%\nof\nPrimary\nNSV',
    type: 'lost',
    getter: calculateDPMPercent,
    measureIn: 'percentage',
  },
  {
    shortName: 'DPMA',
    name: 'D-P-M-A',
    type: 'lost',
    getter: calculateDPMA,
    measureIn: 'value',
  },
  {
    shortName: 'DPMAPercent',
    name: 'D-P-M-A\nas\n%\nof\nPrimary\nNSV',
    type: 'lost',
    getter: calculateDPMAPercent,
    measureIn: 'percentage',
  },
  {
    shortName: 'EBITPercent',
    name: 'EBIT\nas\n%\nof\nPrimary\nNSV',
    type: 'profit',
    getter: calculateEBITPercent,
    measureIn: 'percentage',
  },
];

// KPIS selected in Grouping
export const BREAKDOWN_KPIS_GROUPING_INFO_LIST: Array<KpiMetadata> = [
  {
    shortName: 'FIEDemandValue',
    name: 'Primary\nDemand\nValue',
    type: 'profit',
    measureIn: 'value',
  },
  {
    shortName: 'NetFIESales',
    name: 'Primary\nNet\nSales\nValue',
    type: 'profit',
    getter: calculateNetFIESales,
    measureIn: 'value',
  },
  {
    shortName: 'FIEDemandVolume',
    name: 'Primary Demand Volume',
    type: 'profit',
    measureIn: 'value',
  },
  {
    shortName: 'FIETradeExpenses',
    name: 'Primary\nTrade\nExpenses',
    type: 'lost',
    measureIn: 'value',
  },
  {
    shortName: 'storageAndDistribution',
    name: 'Storage\nAnd\nDistribution',
    type: 'lost',
    getter: calculateStorageAndDistribution,
    measureIn: 'value',
  },
  {
    shortName: 'cbm',
    name: 'Profit Before Marketing',
    type: 'profit',
    getter: calculateCbm,
    measureIn: 'value',
  },
  {
    shortName: 'averageInventoryValue',
    name: 'Average\nInventory\nValue',
    type: 'lost',
    measureIn: 'value',
  },
];

export const BREAKDOWN_FINANCE_KPIS_GROUPING_INFO_LIST: Array<KpiMetadata> = [
  {
    shortName: 'LocalDemandValue',
    name: 'Secondary Demand Value',
    type: 'profit',
    measureIn: 'value',
  },
  {
    shortName: 'LocalDemandVolume',
    name: 'Secondary Demand Volume',
    type: 'profit',
    measureIn: 'value',
  },
  {
    shortName: 'LocalSalesVolume',
    name: 'Secondary Sales Volume',
    type: 'profit',
    measureIn: 'value',
  },
  {
    shortName: 'GrossLocalSales',
    name: 'Secondary Gross Sales',
    type: 'profit',
    measureIn: 'value',
  },
  {
    shortName: 'LocalTradeExpenses',
    name: 'Secondary Trade Expenses',
    type: 'lost',
    measureIn: 'value',
  },
  {
    shortName: 'NetLocalSales',
    name: 'Secondary\nNet\nSales',
    type: 'profit',
    getter: calculateNetLocalSales,
    measureIn: 'value',
  },
  {
    shortName: 'FIEDemandValue',
    name: 'Primary\nDemand\nValue',
    type: 'profit',
    measureIn: 'value',
  },
  {
    shortName: 'FIEDemandVolume',
    name: 'Primary Demand Volume',
    type: 'profit',
    measureIn: 'value',
  },
  {
    shortName: 'GrossFIESales',
    name: 'Primary\nGross\nSales\nValue',
    type: 'profit',
    measureIn: 'value',
  },
  {
    shortName: 'grossSalesValue',
    name: 'Gross Sales\nValue',
    type: 'profit',
    measureIn: 'value',
  },
  {
    shortName: 'FIETradeExpenses',
    name: 'Primary\nTrade\nExpenses',
    type: 'lost',
    measureIn: 'value',
  },
  {
    shortName: 'NetFIESales',
    name: 'Primary\nNet\nSales\nValue',
    type: 'profit',
    getter: calculateNetFIESales,
    measureIn: 'value',
  },
  { shortName: 'cogs', name: 'COGS', type: 'lost', measureIn: 'value' },
  {
    shortName: 'COGSPercent',
    name: 'COGS\nas\n%\nof\nPrimary', 
    type: 'lost',
    getter: calculateCOGSPercent,
    measureIn: 'percentage',
  },
  {
    shortName: 'GrossProfit',
    name: 'Gross\nProfit',
    type: 'profit',
    getter: calculateGrossProfit,
    measureIn: 'value',
  },
  {
    shortName: 'GrossMargin',
    name: 'Gross\nMargin',
    type: 'profit',
    getter: calculateGrossMargin,
    measureIn: 'percentage',
  },
  {
    shortName: 'Transport',
    name: 'Transport',
    type: 'lost',
    measureIn: 'value',
  },
  {
    shortName: 'StorageAndHandling',
    name: 'Storage and Handling',
    type: 'lost',
    measureIn: 'value',
  },
  {
    shortName: 'SupplyChainCost',
    name: 'Supply\nChain\nCost',
    type: 'lost',
    getter: calculateSupplyChainCost,
    measureIn: 'value',
  },
  {
    shortName: 'Promotion',
    name: 'Promotion',
    type: 'lost',
    measureIn: 'value',
  },
  {
    shortName: 'MedicalAffairs',
    name: 'Medical Affairs',
    type: 'lost',
    measureIn: 'value',
  },
  {
    shortName: 'DPM',
    name: 'D-P-M',
    type: 'lost',
    getter: calculateDPM,
    measureIn: 'value',
  },
  {
    shortName: 'DPMPercent',
    name: 'D-P-M\nas\n%\nof\nPrimary\nNSV',
    type: 'lost',
    getter: calculateDPMPercent,
    measureIn: 'percentage',
  },
  {
    shortName: 'Administration',
    name: 'Administration',
    type: 'lost',
    measureIn: 'value',
  },
  {
    shortName: 'DPMA',
    name: 'D-P-M-A',
    type: 'lost',
    getter: calculateDPMA,
    measureIn: 'value',
  },
  {
    shortName: 'DPMAPercent',
    name: 'D-P-M-A\nas\n%\nof\nPrimary\nNSV',
    type: 'lost',
    getter: calculateDPMAPercent,
    measureIn: 'percentage',
  },
  {
    shortName: 'EBIT',
    name: 'EBIT',
    type: 'profit',
    getter: calculateEBIT,
    measureIn: 'value',
  }, 
  {
    shortName: 'EBITPercent',
    name: 'EBIT\nas\n%\nof\nPrimary\nNSV',
    type: 'profit',
    getter: calculateEBITPercent,
    measureIn: 'percentage',
  },
  {
    shortName: 'UnconstrainedCLS',
    name: 'Unconstrained\nDistributor\nClosing\nStock\nVolume',
    type: 'lost',
    measureIn: 'value',
  },
  {
    shortName: 'ConstrainedCLS',
    name: 'Constrained\nDistributor\nClosing\nStock\nVolume',
    type: 'lost',
    measureIn: 'value',
  },
  {
    shortName: 'UnconstrainedCLSGSV',
    name: 'Unconstrained\nDistributor\nClosing\nStock\nValue',
    type: 'lost',
    measureIn: 'value',
  },
  {
    shortName: 'ConstrainedCLSGSV',
    name: 'Constrained\nDistributor\nClosing\nStock\nValue',
    type: 'lost',
    measureIn: 'value',
  },
  {
    shortName: 'UnconstrainedSIT',
    name: 'Unconstrained\nSIT',
    type: 'lost',
    getter: calculateUnconstrainedSIT,
    measureIn: 'value',
  },
  {
    shortName: 'ConstrainedSIT',
    name: 'Constrained\nSIT',
    type: 'lost',
    getter: calculateConstrainedSIT,
    measureIn: 'value',
  },
];

/** List of KPI metadatas with quantity unit */
export const QUANTITY_BIZ_DRIVER_KPIS = [
  'Demand Volume',
  'Sales Volume',
  'CO2 Emission',
  'Inbound CO2 Emission',
  'Storage CO2 Emission',
  'Outbound CO2 Emission',
]

/** List of KPI metadatas with currency */
export const CURRENCY_BIZ_DRIVER_KPIS = [
  'Demand Value',
  'Gross Sales Value',
  'Trade Expenses',
  'Distribution Cost',
  'Net Sales Value',
  'COGS',
  'Stock Transfer',
  'Storage',
  'Handling Cost',
  'Storange And Handling',
  'Storage And Distribution',
  'Profit Before Marketing'
]

/** List of KPI metadatas with percentage */
export const PERCENTAGE_BIZ_DRIVER_KPIS = [
  'Margin',
  'Service Level'
]

/** Lookup of KPI metadata where key is the kpi shortname. */
export const BREAKDOWN_KPIS_INFO = [...BREAKDOWN_KPIS_INFO_LIST, ...BREAKDOWN_FINANCE_KPIS_INFO_LIST].reduce(
  (prev, curr) => ({
    ...prev,
    [curr.shortName]: curr,
  }),
  {} as Record<BreakdownKpis, KpiMetadata>,
);

export function filterAtomicKpis(kpis: Array<{ name: string, label: string }>, workspace: Workspace | undefined, financeKpis: Array<{ name: string, label: string }> = []) {
  // filter ATOMIC_KPIS with hiddenKpis
  const hiddenKpis = workspace?.settings?.hiddenKpis || [];
  const displayFinanceKpis = workspace?.settings?.displayFinanceKpis || false;
  const combinedKpis = displayFinanceKpis ? [...kpis, ...financeKpis] : kpis;
  return combinedKpis.filter(kpi => !hiddenKpis.includes(kpi.name));
}

export function filterBreakdownKpisInfoList(kpis: Array<KpiMetadata>, workspace: Workspace | undefined, financeKpis: KpiMetadata[] = []) {
  // filter BREAKDOWN_KPIS_INFO_LIST with hiddenKpis
  const hiddenKpis = workspace?.settings?.hiddenKpis || [];
  const displayFinanceKpis = workspace?.settings?.displayFinanceKpis || false;
  const combinedKpis = displayFinanceKpis ? [...kpis, ...financeKpis] : kpis;
  return combinedKpis.filter(kpi => !hiddenKpis.includes(kpi.shortName));
}

/** Returns KPI numbers given a shortname. The shortname must exist in KPIS_INFO. */
export function kpiOf(breakdown: Breakdown | undefined, shortName: BreakdownKpis): number | null {
  if (!breakdown) return 0;

  const metadata = BREAKDOWN_KPIS_INFO[shortName];
  if (metadata?.getter) {
    return metadata.getter(breakdown);
  }
  return breakdown[shortName as keyof Breakdown] as number;
}

function getKpiUnitLabel(kpiUnit: string) {
  const kpiLabel = KPI_UNIT_LABEL.find((k) => k.unit === kpiUnit)?.label || kpiUnit

  if (!kpiLabel) {
    console.log("Can not found label of KpiUnit "+kpiUnit)
  }

  return kpiLabel
}

/** Returns the unit base on KPI name */
export function unitOfKpi(kpiName: string, workspace: Workspace, selectedCurrency?: string) {
  if (!workspace) {
    return ""
  }

  if (QUANTITY_KPIS.includes(kpiName) ||
    QUANTITY_BREAKDOWN_KPIS.includes(kpiName) ||
    QUANTITY_BIZ_DRIVER_KPIS.includes(kpiName)) {

    // CO2 KPI only use Kg
    if (
      ['CO2', 'InboundCO2', 'StorageCO2', 'OutboundCO2'].includes(kpiName)
      || kpiName.includes(('CO2 Emission'))
    ) {
      return " (KG)"
    }

    return " (" + getKpiUnitLabel(workspace.defaultQuantityUnit) + ")"
  }

  if (CURRENCY_KPIS.includes(kpiName) ||
    CURRENCY_BREAKDOWN_KPIS.includes(kpiName) ||
    CURRENCY_BIZ_DRIVER_KPIS.includes(kpiName)) {
    return " (" + getKpiUnitLabel(selectedCurrency || workspace.defaultCurrency) + ")"
  }

  if (PERCENTAGE_KPIS.includes(kpiName) ||
    PERCENTAGE_BREAKDOWN_KPIS.includes(kpiName) ||
    PERCENTAGE_BIZ_DRIVER_KPIS.includes(kpiName)) {
    return " (%)"
  }

  if (DAYS_KPIS.includes(kpiName)) {
    return ' (DAYS)';
  }

  console.log("Unknow UOM for kpiName ", kpiName)
  return ""

}
