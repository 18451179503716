import { createReducer, on } from '@ngrx/store';
import {
  LOAD_FORECAST_EXPLORER_ALL_DATA,
  LOAD_FORECAST_EXPLORER_ALL_DATA_FAILED,
  LOAD_FORECAST_EXPLORER_ALL_DATA_LENGTH,
  LOAD_FORECAST_EXPLORER_ALL_DATA_LENGTH_FAILED,
  LOAD_FORECAST_EXPLORER_ALL_DATA_LENGTH_SUCCESS,
  LOAD_FORECAST_EXPLORER_ALL_DATA_SUCCESS,
  LOAD_FORECAST_EXPLORER_HEATMAP_DATA,
  LOAD_FORECAST_EXPLORER_HEATMAP_DATA_FAILED,
  LOAD_FORECAST_EXPLORER_HEATMAP_DATA_LENGTH,
  LOAD_FORECAST_EXPLORER_HEATMAP_DATA_LENGTH_FAILED,
  LOAD_FORECAST_EXPLORER_HEATMAP_DATA_LENGTH_SUCCESS,
  LOAD_FORECAST_EXPLORER_HEATMAP_DATA_SUCCESS,
  LOAD_FORECAST_EXPLORER_METRICS,
  LOAD_FORECAST_EXPLORER_METRICS_FAILED,
  LOAD_FORECAST_EXPLORER_METRICS_SUCCESS,
  LOAD_FORECAST_EXPLORER_TIME_SERIES_DATA,
  LOAD_FORECAST_EXPLORER_TIME_SERIES_DATA_FAILED,
  LOAD_FORECAST_EXPLORER_TIME_SERIES_DATA_SUCCESS,
} from './forecast-explorer.actions';

export const PAGE__FORECAST_EXPLORER_STATE_KEY = 'page_forecastExplorer';

/*************************************
 * State
 *************************************/

export interface IForecastExplorerPageState {
  forecastExplorerAllData: any;
  forecastExplorerAllDataLoading: boolean;
  forecastExplorerTimeSeriesData: any;
  forecastExplorerTimeSeriesDataLoading: boolean;
  forecastExplorerMetrics: any;
  forecastExplorerMetricsLoading: boolean;
  forecastExplorerHeatmapData: any;
  forecastExplorerHeatmapDataLoading: boolean;
  forecastExplorerError: any;
  forecastReportError: any;
  forecastExplorerAllDataLength: number;
  forecastExplorerHeatmapDataLength: number;
}

export const initialState: IForecastExplorerPageState = {
  forecastExplorerAllData: undefined,
  forecastExplorerAllDataLoading: false,
  forecastExplorerTimeSeriesData: undefined,
  forecastExplorerTimeSeriesDataLoading: false,
  forecastExplorerMetrics: undefined,
  forecastExplorerMetricsLoading: false,
  forecastExplorerHeatmapData: undefined,
  forecastExplorerHeatmapDataLoading: false,
  forecastExplorerError: undefined,
  forecastReportError: undefined,
  forecastExplorerAllDataLength: 0,
  forecastExplorerHeatmapDataLength: 0,
};

/*************************************
 * Reducers
 *************************************/

export const page_forecastExplorerReducer = createReducer(
  initialState,
  on(
    LOAD_FORECAST_EXPLORER_METRICS,
    (state): IForecastExplorerPageState => ({
      ...state,
      forecastExplorerMetrics: undefined,
      forecastExplorerMetricsLoading: true
    })
  ),
  on(
    LOAD_FORECAST_EXPLORER_METRICS_SUCCESS,
    (state, { data }): IForecastExplorerPageState => ({
      ...state,
      forecastExplorerMetrics: data,
      forecastExplorerMetricsLoading: false
    })
  ),
  on(
    LOAD_FORECAST_EXPLORER_METRICS_FAILED,
    (state, { error }): IForecastExplorerPageState => ({
      ...state,
      forecastExplorerMetrics: undefined,
      forecastExplorerMetricsLoading: false,
      forecastExplorerError: error
    })
  ),

  on(
    LOAD_FORECAST_EXPLORER_ALL_DATA,
    (state): IForecastExplorerPageState => ({
      ...state,
      forecastExplorerAllData: undefined,
      forecastExplorerAllDataLoading: true
    })
  ),
  on(
    LOAD_FORECAST_EXPLORER_ALL_DATA_SUCCESS,
    (state, { data }): IForecastExplorerPageState => {
      const prevForecastExplorerAllData = state.forecastExplorerAllData;
      const mergedData = prevForecastExplorerAllData === undefined ? [...data.data] : [...prevForecastExplorerAllData.data, ...data.data];
      
      return {
        ...state,
        forecastExplorerAllData: {
          ...data,
          data: mergedData,
        },
        forecastExplorerAllDataLoading: false,
      };
    }
  ),
  on(
    LOAD_FORECAST_EXPLORER_ALL_DATA_FAILED,
    (state, { error }): IForecastExplorerPageState => ({
      ...state,
      forecastExplorerAllData: undefined,
      forecastExplorerAllDataLoading: false,
      forecastExplorerError: error
    })
  ),
  on(
    LOAD_FORECAST_EXPLORER_TIME_SERIES_DATA,
    (state): IForecastExplorerPageState => ({
      ...state,
      forecastExplorerTimeSeriesData: undefined,
      forecastExplorerTimeSeriesDataLoading: true
    })
  ),
  on(
    LOAD_FORECAST_EXPLORER_TIME_SERIES_DATA_SUCCESS,
    (state, { data }): IForecastExplorerPageState => ({
      ...state,
      forecastExplorerTimeSeriesData: data,
      forecastExplorerTimeSeriesDataLoading: false,
    })
  ),
  on(
    LOAD_FORECAST_EXPLORER_TIME_SERIES_DATA_FAILED,
    (state, { error }): IForecastExplorerPageState => ({
      ...state,
      forecastExplorerTimeSeriesData: undefined,
      forecastExplorerTimeSeriesDataLoading: false,
      forecastExplorerError: error
    })
  ),
  on(
    LOAD_FORECAST_EXPLORER_HEATMAP_DATA,
    (state): IForecastExplorerPageState => ({
      ...state,
      forecastExplorerHeatmapData: undefined,
      forecastExplorerHeatmapDataLoading: true
    })
  ),
  on(
    LOAD_FORECAST_EXPLORER_HEATMAP_DATA_SUCCESS,
    (state, { data }): IForecastExplorerPageState => {
      const prevForecastExplorerHeatmapData = state.forecastExplorerHeatmapData;
      const mergedData = prevForecastExplorerHeatmapData === undefined ? [...data.data] : [...prevForecastExplorerHeatmapData.data, ...data.data];
      
      return {
        ...state,
        forecastExplorerHeatmapData: {
          ...data,
          data: mergedData,
        },
        forecastExplorerHeatmapDataLoading: false,
      };
    }
  ),
  on(
    LOAD_FORECAST_EXPLORER_HEATMAP_DATA_FAILED,
    (state, { error }): IForecastExplorerPageState => ({
      ...state,
      forecastExplorerHeatmapData: undefined,
      forecastExplorerHeatmapDataLoading: false,
      forecastExplorerError: error
    })
  ),
  on(
    LOAD_FORECAST_EXPLORER_ALL_DATA_LENGTH,
    (state): IForecastExplorerPageState => ({
      ...state,
      forecastExplorerAllDataLength: 0,
      forecastExplorerAllDataLoading: true
    })
  ),
  on(
    LOAD_FORECAST_EXPLORER_ALL_DATA_LENGTH_SUCCESS,
    (state, { data }): IForecastExplorerPageState => ({
      ...state,
      forecastExplorerAllDataLength: data?.length || 0,
      forecastExplorerAllDataLoading: false,
    })
  ),
  on(
    LOAD_FORECAST_EXPLORER_ALL_DATA_LENGTH_FAILED,
    (state, { error }): IForecastExplorerPageState => ({
      ...state,
      forecastExplorerAllDataLength: 0,
      forecastExplorerAllDataLoading: false,
      forecastExplorerError: error
    })
  ),
  on(
    LOAD_FORECAST_EXPLORER_HEATMAP_DATA_LENGTH,
    (state): IForecastExplorerPageState => ({
      ...state,
      forecastExplorerHeatmapDataLength: 0,
      forecastExplorerHeatmapDataLoading: true
    })
  ),
  on(
    LOAD_FORECAST_EXPLORER_HEATMAP_DATA_LENGTH_SUCCESS,
    (state, { data }): IForecastExplorerPageState => ({
      ...state,
      forecastExplorerHeatmapDataLength: data?.length || 0,
      forecastExplorerHeatmapDataLoading: false,
    })
  ),
  on(
    LOAD_FORECAST_EXPLORER_HEATMAP_DATA_LENGTH_FAILED,
    (state, { error }): IForecastExplorerPageState => ({
      ...state,
      forecastExplorerHeatmapDataLength: 0,
      forecastExplorerHeatmapDataLoading: false,
      forecastExplorerError: error
    })
  ),
);
