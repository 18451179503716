import { createReducer, on } from '@ngrx/store';

import { Notification } from '@/app/@core/interfaces/business/notification';
import { ADD_NOTIFICATION_SUCCCESS, CHECK_ALL_NOTIFICATIONS_SUCCESS, LOAD_NOTIFICATIONS, LOAD_NOTIFICATIONS_FAILED, LOAD_NOTIFICATIONS_SUCCESS } from './notification.actions';

export const NOTIFICATION_STATE_KEY = 'notification';

/*************************************
 * State
 *************************************/

export interface INotificationState {
  data: ReadonlyArray<Notification>;
  loading: boolean;
  errors: string[];
}
export const initialState: INotificationState = {
  data: [],
  loading: false,
  errors: [],
};

/*************************************
 * Reducers
 *************************************/

export const notificationReducer = createReducer(
  initialState,
  on(
    LOAD_NOTIFICATIONS,
    (state: INotificationState): INotificationState => {
      return {
        ...state,
        loading: true,
      };
    },
  ),
  on(
    LOAD_NOTIFICATIONS_SUCCESS,
    (state, { data }): INotificationState => {
      return {
        ...state,
        loading: false,
        data,
      };
    },
  ),
  on(
    LOAD_NOTIFICATIONS_FAILED,
    (state, { error }): INotificationState => ({
      ...state,
      loading: false,
      data: [],
      errors: error
    }),
  ),
  on(
    CHECK_ALL_NOTIFICATIONS_SUCCESS,
    (state): INotificationState => ({
      ...state,
      data: state.data.map((i) => ({...i, checked: true}))
    }),
  ),
  on(
    ADD_NOTIFICATION_SUCCCESS,
    (state, { data }): INotificationState => ({
      ...state,
      data: [{...data}, ...state.data]
    }),
  ),
);
