import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'kpiFormatting',
})
export class KpiFormattingPipe implements PipeTransform {
  transform(input: string | number | null, acceptedScale?: string[], decimalPlaces: number = 2, numericScale: string = ''): string {
    if (input === null) return '-';
    return transformInputToKpiFormat(input, '-', acceptedScale, decimalPlaces, numericScale);
  }
}

/**
 * Transforms an large input number values into a human-readable string format. Set the param
 * `fallbackValueOnInvalid` to return an alternate string if the input is not a valid number.
 *
 * For example:
 * ```
 * transformInputToKpiFormat(1000)          === '1k'
 * transformInputToKpiFormat(2345678)       === '2.34M'
 * transformInputToKpiFormat('test', '---') === '---'
 * ```
 */
// change formate toFixed(1);
export function transformInputToKpiFormat(
  input?: string | number | null,
  fallbackValueOnInvalid?: string,
  acceptedScale?: string[],
  decimalPlaces: number = 0,
  numericScale: string = '',
): string {
  if (fallbackValueOnInvalid) {
    if (typeof input !== 'number' || Number.isNaN(input) || input == null || input == undefined) {
      return fallbackValueOnInvalid;
    }
  }

  if (Number.isNaN(input) || input == null || input == undefined) return '';
  if (typeof input === 'string') input = parseFloat(input);

  // Reference: https://app.asana.com/0/1206108736753148/1206108736753145/f
  const suffixes = ['k', 'M', 'B', 'T', 'q', 'Q', 's', 'S'];
  
  const inputIsNegative = Math.sign(input);
  const absInput = Math.abs(input);

  const exp = Math.floor(Math.log(absInput) / Math.log(1000));
  const result = (absInput > 1 ? (absInput / Math.pow(1000, exp)) : absInput) * inputIsNegative;
  const isEffectivelyZero = isInputEffectivelyZero(absInput, decimalPlaces);
  const formattedNumber = isEffectivelyZero
    ? formatSmallNumber(absInput, inputIsNegative, decimalPlaces, isEffectivelyZero)
    : result.toLocaleString(undefined, { minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces });

  const returnedValue = ['', 'D'].includes(numericScale)
    ? formattedNumber + ' ' + (suffixes[exp - 1] || '')
    : formatNumberByScale(input, decimalPlaces, numericScale);

  return returnedValue;
}

export function isInputEffectivelyZero(input, decimalPlaces: number) {
  // Round the input based on decimalPlaces and check if it's effectively zero
  const roundedInput = parseFloat(input.toFixed(decimalPlaces));
  return roundedInput === 0;
}

export function formatSmallNumber(input, inputIsNegative: number, decimalPlaces: number, isEffectivelyZero: boolean) {
  // This check is essential to avoid cases like '-0', '-0.0', '-0.00', etc.
  const signPrefix = inputIsNegative < 0 && !isEffectivelyZero ? '-' : '';
  return signPrefix + input.toLocaleString(undefined, { minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces });
}

export function formatNumberByScale(number: number, decimalPlaces: number = 0, numericScale: string = '') {
  const scales = {
    'S': 1e24,
    's': 1e21,
    'Q': 1e18,
    'q': 1e15,
    'T': 1e12,
    'B': 1e9,
    'M': 1e6,
    'k': 1e3,
    'U': 1e0,
  };
  // Reference: https://egg-inc.fandom.com/wiki/Order_of_Magnitude

  const unit = scales[numericScale];
  const result = !unit ? number : number / unit;

  return result.toLocaleString(undefined, {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces
  }) + ' ' + (unit && numericScale !== 'U' ? numericScale : '');
}
