import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  OnInit
} from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService, NbPopoverDirective } from '@nebular/theme';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';

import { AbilityService } from '@/app/services/ability.service';

import { Plan, MenuTreeNode, PlanEntry, PlanFlag } from '../../../@core/interfaces/business/plan';
import { PlanManagementComponent } from '../../../pages/plan-management/plan-management.component';
import {
  PLANNING_EXPLORER_PAGE,
  DEMAND_PLANNING_PAGE,
  SUPPLY_EXPLORER_PAGE,
  EVENT_MANAGEMENT_PAGE,
  BUSINESS_EXPLORER_PAGE,
  SIT_PAGE
} from '../planning-segment/widget-management.component';
import { Notification } from '@/app/@core/interfaces/business/notification';
import { select_selectedActualsAndPlan } from '@/store/event/event.selectors';
import { select_sortedPlan } from '@/store/plan/plan.selectors';
import { select_selectedWorkspace, select_isNewBaseDemandAvailable } from '@/store/workspace/workspace.selectors';
import { GET_BASE_DEMAND_AVAILABILITY } from '@/store/workspace/workspace.actions';
import { FETCH_PENDING_FORECAST_EXPLORER_TASKS } from '@/store/pages/layout/layout.actions';
import { select_hasPendingForecastExplorerTasks } from '@/store/pages/layout/layout.selectors';

const PAGE_TILES = {
  [BUSINESS_EXPLORER_PAGE]: 'Business Explorer',
  [PLANNING_EXPLORER_PAGE]: 'Planning Explorer',
  [DEMAND_PLANNING_PAGE]: 'Demand Planning',
  [SUPPLY_EXPLORER_PAGE]: 'Supply Explorer',
  [EVENT_MANAGEMENT_PAGE]: 'Event Management',
  [SIT_PAGE]: 'Stock-In-Trade Planning'
};

export enum Message {
  PrimaryScenarioChanged = "Primary scenario of one or more active plans has changed",
  NewBaseDemandAvailable = "New Base Demand is available",
  NewForecastBaseAvailable = "New Forecast Base is available"
}

@Component({
  selector: 'cel-planning-menu',
  templateUrl: './planning-menu.component.html',
  styleUrls: ['./planning-menu.component.scss']
})
export class PlanningMenuComponent implements OnInit {

  /** Selected plan di in the dropdown. */
  @Input() selected?: Plan;

  @Input() plans: MenuTreeNode<PlanEntry>[] = []
  /** List of notifications */
  @Input() notifications: readonly Notification[] = [];

  @Input() hasPendingForecastBaseTasks: boolean = false;

  /** Emits whenever the user selects a plan. */
  @Output() selectedChange = new EventEmitter<Plan>();

  @ViewChild(NbPopoverDirective) popover?: NbPopoverDirective;

  isActivePlan = false;
  isOutdatedPlan = false;
  shouldDisableShareButton = false;
  messages: Message[] = [];
  showForecasterLoader: boolean = false;

  canManagePlan?: boolean;

  constructor(
    private readonly dialogService: NbDialogService,
    private readonly router: Router,
    private readonly store: Store,
    private readonly abilityService: AbilityService,
  ) {}

  ngOnInit(): void {
    const ability = this.abilityService.getAbility();

    this.canManagePlan = ability.can('manage', 'Plan');

    combineLatest([
      this.store.select(select_sortedPlan),
      this.store.select(select_selectedActualsAndPlan),
      this.store.select(select_selectedWorkspace),
      this.store.select(select_isNewBaseDemandAvailable),
    ]).subscribe(([plans, selectedActualsAndPlan, selectedWorkspace, isNewBaseDemandAvailable]) => {
      if (selectedActualsAndPlan) {
        const { latestActual, selectedPlan } = selectedActualsAndPlan;

        const isActualPlan = selectedPlan?.flags?.includes(PlanFlag.ACTUAL)

        if (isActualPlan) {
          this.isActivePlan = false;
        } else {
          this.isActivePlan = this.checkActivePlan(new Date(latestActual.actualEndDate).toISOString(), selectedPlan.futurePlanStartDate);
        }

        const selectedPlanIndex = plans.findIndex((plan: Plan) => plan.id === selectedPlan.id);
        const previousPlans = plans.filter((_, index) => index > selectedPlanIndex);
        const previousActivePlans = previousPlans.filter(
          (plan: Plan) => this.checkActivePlan(
            new Date(latestActual.actualEndDate).toISOString(),
            plan.futurePlanStartDate
          )
        )

        const isPrimaryChanged = previousActivePlans.some(
          (plan: Plan) => 
            plan.previousPrimaryScenario && 
            plan.previousPrimaryScenario !== plan.primaryScenario
        );
        
        this.updateMessages(Message.PrimaryScenarioChanged, isPrimaryChanged);
        this.updateMessages(Message.NewBaseDemandAvailable, isNewBaseDemandAvailable);

        this.isOutdatedPlan = (isPrimaryChanged && previousActivePlans.length > 0) || isNewBaseDemandAvailable; // || otherConditions

        if (selectedWorkspace?.settings?.isShowShareButton === false) {
          this.shouldDisableShareButton = true;
        }
      }
    });
    
    this.store.dispatch(FETCH_PENDING_FORECAST_EXPLORER_TASKS());

    this.store.select(select_hasPendingForecastExplorerTasks).subscribe((hasPendingForecastExplorerTasks) => {
      this.showForecasterLoader = hasPendingForecastExplorerTasks;
    })
  }

  updateMessages(message: Message, condition: boolean) {
    const messageExists = this.messages.includes(message);

    if (condition && !messageExists) {
      this.messages.push(message);
    } 
    
    if (!condition && messageExists) {
      const index = this.messages.indexOf(message);
      this.messages.splice(index, 1);
    }
  }

  checkActivePlan(actualEndDateStr: string, planStartDateStr: string): boolean {
    const actualEndTimestamp = Date.parse(actualEndDateStr);
    const planStartTimestamp = Date.parse(planStartDateStr);

    return actualEndTimestamp < planStartTimestamp;
  }

  getPageTitle(): string {
    const title = PAGE_TILES[this.router.url as keyof typeof PAGE_TILES];
    return title || '';
  }

  openNewPlanWindow() {
    this.dialogService.open(PlanManagementComponent, {
      hasBackdrop: true,
      closeOnEsc: true,
      closeOnBackdropClick: false,
    });
  }

  selectedPlan(plan: Plan) {
    if (plan) {
      this.selectedChange.emit(plan);
      this.popover?.hide();
      this.store.dispatch(GET_BASE_DEMAND_AVAILABILITY({ id: plan.workspace.id ?? plan.workspace }));
    }
  }

  // Method to generate id based on whether the item has children or not
  generateId(row: any): string {
    if (row.children && row.children.length > 0) {
      if (row.level == 0) {
        return `${row.data.name.replace(/\s+/g, '-').toLowerCase()}`
      }
      return `parent-${row.data.name.replace(/\s+/g, '-').toLowerCase()}`;  // ID for items with children
    }

    return `item-${row.data.name.replace(/\s+/g, '-').toLowerCase()}`;  // ID for items without children
  }

  // onDeletePlan(plan: Plan) {
  //   if (plan) {
  //     this.deletePlan.emit(plan);
  //     this.popover?.hide()
  //   }
  // }
}
