import { Workspace } from '@/app/@core/interfaces/common/workspace';
import { MasterFieldDisplayNamePipe } from '@/app/shared/master-field-display-name.pipe';

export function getSITMetricsNamingValues(name: string, workspace: Workspace | undefined): string {
  const sitFieldDisplayNames = workspace?.settings?.sitFieldDisplayNames;

  if (!sitFieldDisplayNames || !sitFieldDisplayNames[name] || !workspace) {
    return name;
  }

  return MasterFieldDisplayNamePipe.prototype.transform.call(
    null,
    name,
    sitFieldDisplayNames
  );
}