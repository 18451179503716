import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { ScrollingModule } from '@angular/cdk/scrolling';
import {
  NbChatModule,
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule,
  NbWindowModule,
  NbTooltipModule
} from '@nebular/theme';
import { NgxEchartsModule } from 'ngx-echarts';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { EntityDataModule } from '@ngrx/data';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import { AbilityModule } from '@casl/angular';
import * as Sentry from '@sentry/angular-ivy';

import { CoreModule } from './@core/core.module';
import { AbilityService } from './services/ability.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ThemeModule } from './@theme/theme.module';
import { AuthModule } from './@auth/auth.module';
import { entityConfig } from './@core/entity/entity.metadata';
import { environment } from '../environments/environment';
import { EntityStoreModule } from './@core/entity/entity-store.module';
import { PAGE__LAYOUT_STATE_KEY, page_layoutReducer } from '@/store/pages/layout/layout.state';
import { EVENT_STATE_KEY, eventReducer } from '@/store/event/event.state';
import { WORKSPACE_STATE_KEY, workspaceReducer } from '@/store/workspace/workspace.state';
import { PAGE__SIT_STATE_KEY, page_sitReducer } from '@/store/pages/sit/sit.state';
import { SCENARIO_STATE_KEY, scenarioReducer } from '@/store/scenario/scenario.state';
import { PLAN_STATE_KEY, planReducer } from '@/store/plan/plan.state';
import { PLAN_SETTING_STATE_KEY, planSettingReducer } from '@/store/plan-setting/planSetting.state';
import { ACTUAL_STATE_KEY, actualReducer } from '@/store/actual/actual.state';
import { SEGMENT_STATE_KEY, segmentReducer } from '@/store/segment/segment.state';
import { TASK_STATE_KEY, taskReducer } from '@/store/task/task.state';
import { WIDGET_STATE_KEY, widgetReducer } from '@/store/widget/widget.state';
import { PLAN_IMPORT_STATE_KEY, planImportReducer } from '@/store/plan-import/plan-import.state';
import { PAGE__BIZ_STATE_KEY, page_bizReducer } from '@/store/pages/planning-explorer/planning-explorer.state';
import { PAGE__DEMAND_PLANNING_STATE_KEY, page_demandPLanningReducer } from '@/store/pages/demand-planning/demand-planning.state';
import { PAGE__FORECAST_EXPLORER_STATE_KEY, page_forecastExplorerReducer } from '@/store/pages/forecast-explorer/forecast-explorer.state';
import { PAGE__EVENTS_MANAGEMENT_STATE_KEY, page_eventsManagementReducer } from '@/store/pages/event-management/event-management.state';
import { PAGE__INPUT_DATA_STATE_KEY, page_inputDataReducer } from '@/store/pages/input-data/input-data.state';
import { NOTIFICATION_STATE_KEY, notificationReducer } from '@/store/notification/notification.state';
import { DEMAND_GENERATION_STATE_KEY, demandGenerationReducer } from '@/store/demand-generation/demand-generation.state';
import { ETL_STATE_KEY, etlReducer } from '@/store/etl/etl.state';

@NgModule({
  declarations: [AppComponent],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    AbilityService
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NbTooltipModule,
    AuthModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    NbChatModule.forRoot({ messageGoogleMapKey: 'AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY' }),
    NbDateFnsDateModule.forRoot({ format: 'd MMM yy' }),
    CoreModule.forRoot(),
    ThemeModule.forRoot(),
    StoreModule.forRoot({}),
    StoreModule.forFeature(EVENT_STATE_KEY, eventReducer),
    StoreModule.forFeature(PLAN_STATE_KEY, planReducer),
    StoreModule.forFeature(SCENARIO_STATE_KEY, scenarioReducer),
    StoreModule.forFeature(PLAN_SETTING_STATE_KEY, planSettingReducer),
    StoreModule.forFeature(ACTUAL_STATE_KEY, actualReducer),
    StoreModule.forFeature(SEGMENT_STATE_KEY, segmentReducer),
    StoreModule.forFeature(TASK_STATE_KEY, taskReducer),
    StoreModule.forFeature(WIDGET_STATE_KEY, widgetReducer),
    StoreModule.forFeature(WORKSPACE_STATE_KEY, workspaceReducer),
    StoreModule.forFeature(PLAN_IMPORT_STATE_KEY, planImportReducer),
    StoreModule.forFeature(PAGE__LAYOUT_STATE_KEY, page_layoutReducer),
    StoreModule.forFeature(PAGE__BIZ_STATE_KEY, page_bizReducer),
    StoreModule.forFeature(PAGE__DEMAND_PLANNING_STATE_KEY, page_demandPLanningReducer),
    StoreModule.forFeature(PAGE__FORECAST_EXPLORER_STATE_KEY, page_forecastExplorerReducer),
    StoreModule.forFeature(PAGE__EVENTS_MANAGEMENT_STATE_KEY, page_eventsManagementReducer),
    StoreModule.forFeature(PAGE__INPUT_DATA_STATE_KEY, page_inputDataReducer),
    StoreModule.forFeature(PAGE__SIT_STATE_KEY, page_sitReducer),
    StoreModule.forFeature(NOTIFICATION_STATE_KEY, notificationReducer),
    StoreModule.forFeature(DEMAND_GENERATION_STATE_KEY, demandGenerationReducer),
    StoreModule.forFeature(ETL_STATE_KEY, etlReducer),
    EffectsModule.forRoot([]),
    EntityDataModule.forRoot(entityConfig),
    EntityStoreModule,
    StoreDevtoolsModule.instrument({ logOnly: environment.production }),
    NgxEchartsModule.forRoot({ echarts: () => import('echarts') }),
    ScrollingModule,
    AbilityModule
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {}
}
