import { GET_CURRENT_USER_SUCCESS, UPDATE_USER_SETTINGS } from './auth/auth.actions';
import {
  DELETE_EVENT_VERSION_SUCCESS,
  LOAD_EVENTS_SUCCESS, SAVE_EVENT_VERSION_SUCCESS
} from './event/event.actions';
import {
  LOAD_DEMAND_CHART_DATA_SUCCESS,
  LOAD_DEMAND_MULTI_ACTUAL_X_DATA,
  OPEN_FORECAST_SETTINGS_DIALOG,
  SET_DECIMAL_PLACES,
  SET_NUMERIC_SCALE,
  UPDATE_DATE_AGGREGATION,
  UPDATE_DEMAND_ANALYTIC_GROUPING,
  UPDATE_DEMAND_DATASET_YEAR_GROUPING,
  UPDATE_DEMAND_GROUPING,
  UPDATE_UOM,
  TRIGGER_LOAD_DEMAND,
  TRIGGER_LOAD_DEMAND_IMPACT,
  UPDATE_KPI_TRACKER_GROUPING,
  UPDATE_KPI_TRACKER_DATASET_YEAR_GROUPING,
  UPDATE_KPI_TRACKER_ANALYTIC_GROUPING
} from './pages/demand-planning/demand-planning.actions';
import {
  LOAD_SIT_DATA_SUCCESS,
  LOAD_SIT_MULTI_ACTUAL_X_DATA,
  UPDATE_SIT_DATE_AGGREGATION,
  UPDATE_SIT_UOM,
  UPDATE_SIT_GROUPING,
  UPDATE_SIT_CONSTRAINED,
  TRIGGER_SALES_IN_SIMULATION_SUCCESS,
  TRIGGER_LOAD_SIT
} from './pages/sit/sit.actions';
import {
  START_CHECKING_PENDING_FORECAST_BASE_TASKS,
  START_CHECKING_PENDING_DEMANDS_SHAPER_TASKS,
  FORECAST_BASE_TASKS_RAN_SUCCESSFULLY,
  DEMANDS_SHAPER_TASKS_RAN_SUCCESSFULLY
} from './demand-generation/demand-generation.actions';
import {
  CREATE_NEW_PLAN_SUCCESS,
  SELECTED_DATE_RANGE_CHANGED,
  SELECTED_SEGMENT_CHANGED,
  SELECT_PLAN,
  DELETE_PLAN_SUCCESS,
  UPDATE_PLAN_SUCCESS,
  START_CHECKING_FORECAST_REPORT_TASK,
  HIGHLIGHT_SCENARIO,
} from './pages/layout/layout.actions';
import {
  LOAD_INITIAL_BUSINESS_DRIVER_DATA,
  SELECT_ATOMIC_KPI,
  SELECT_BUSINESS_DRIVER_MAIN_DIM,
  SELECT_BUSINESS_DRIVER_MAIN_KPI,
  SELECT_BUSINESS_DRIVER_SECONDARY_DIM,
  SELECT_BUSINESS_DRIVER_SECONDARY_KPI,
  SELECT_BUSINESS_DRIVER_SORT_KEY,
  SELECT_COUNTRY_CODES,
  SELECT_CURRENCY,
  SELECT_KPI_MATRIX_SORT_KEY,
  SELECT_KPI_MATRIX_X_AXIS,
  SELECT_KPI_MATRIX_Y_AXIS,
  SELECT_MAP_KPI,
  SELECT_MATRIX_KPI_1,
  SELECT_MATRIX_KPI_2,
  SET_SCENARIO_SCORE_METRICS,
  UPDATE_SP_CHART_DATE_AGG
} from './pages/planning-explorer/planning-explorer.actions';
import {
  ADD_DISPLAYED_SCENARIO_SUCCESS,
  CHANGED_DISPLAYED_DATERANGE_SUCCESS,
  CHANGED_USING_Y2Y_SUCCESS,
  DELETE_DISPLAYED_SCENARIO_SUCCESS,
  HIDE_DISPLAYED_SCENARIO_SUCCESS,
  LOAD_PLAN_SETTING_SUCCESS
} from './plan-setting/planSetting.actions';
import {
  LOAD_PLANS_SUCCESS,
  SET_PRIMARY_SCENARIO_SUCCESS,
  UPDATE_DISPLAYED_SCENARIO_SUCCESS
} from './plan/plan.actions';
import {
  CLONE_SCENARIO_SUCCESS,
  DELETE_SCENARIO_SUCCESS,
  LOAD_LEGACY_ACTUAL_AND_COMMITTED_SCENARIOS_SUCCESS,
  LOAD_SCENARIOS_SUCCESS,
  SAVE_SCENARIO_SUCCESS
} from './scenario/scenario.actions';
import { DELETE_SEGMENT_SUCCESS, EDIT_SEGMENT_SUCCESS, SAVE_SEGMENT_SUCCESS } from './segment/segment.actions';
import { SCENARIO_JUST_FINISHED_SIMULATION } from './task/task.actions';
import { SELECT_WORKSPACE } from './workspace/workspace.actions';
import { SET_TRADE_TERM_GROUPS } from './pages/event-management/event-management.actions';

/***************************************
 * Common data
 **************************************/

// Load plan after Getting user info, or when selecting Workspace
export const SHOULD_LOAD_PLANS = [
  GET_CURRENT_USER_SUCCESS,
  SELECT_WORKSPACE,
  CREATE_NEW_PLAN_SUCCESS,
  DELETE_PLAN_SUCCESS,
  UPDATE_PLAN_SUCCESS
];

// Load actuals after workspace info
export const SHOULD_LOAD_ACTUALS = [
  GET_CURRENT_USER_SUCCESS,
];

export const SHOULD_LOAD_PLAN_SETTING = [
  // When new Plan are slected/loaded
  LOAD_PLANS_SUCCESS,
  SELECT_PLAN,
  CREATE_NEW_PLAN_SUCCESS,
  // After saved a Scenario
  ADD_DISPLAYED_SCENARIO_SUCCESS,
  HIDE_DISPLAYED_SCENARIO_SUCCESS,
  DELETE_DISPLAYED_SCENARIO_SUCCESS,
  CHANGED_USING_Y2Y_SUCCESS,
  CHANGED_DISPLAYED_DATERANGE_SUCCESS,
];

export const SHOULD_LOAD_DEMAND_MULTI_ACTUAL_X_DATA = [
  LOAD_DEMAND_MULTI_ACTUAL_X_DATA,
  // Should load after all demand chart data loaded
  // Then the latest raw_demands data is ready to calculate demandsActualX
  LOAD_DEMAND_CHART_DATA_SUCCESS,
  // No need to put in SELECTED_SEGMENT_CHANGED and SELECTED_DATE_RANGE_CHANGED
  // because they are dependencies of LOAD_DEMAND_CHART_DATA_SUCCESS
]

export const SHOULD_LOAD_SCENARIOS = [
  // Must reload scenario to fetch the output db
  SCENARIO_JUST_FINISHED_SIMULATION,
  // When new Plan are slected/loaded
  LOAD_PLANS_SUCCESS,
  SELECT_PLAN,
  CREATE_NEW_PLAN_SUCCESS,
  UPDATE_PLAN_SUCCESS,
  // After saved a Scenario
  SAVE_SCENARIO_SUCCESS,
  DELETE_SCENARIO_SUCCESS,
  CLONE_SCENARIO_SUCCESS,
  LOAD_PLAN_SETTING_SUCCESS,
  // after event version is saved
  SAVE_EVENT_VERSION_SUCCESS,
  TRIGGER_SALES_IN_SIMULATION_SUCCESS
];

export const SHOULD_LOAD_PRIMARY_SCENARIOS = [
  SELECT_WORKSPACE,
  LOAD_PLANS_SUCCESS,
  SET_PRIMARY_SCENARIO_SUCCESS,
];

// Load Saved Segments data
export const SHOULD_LOAD_SEGMENTS = [
  // After Plans loaded
  LOAD_PLANS_SUCCESS,
  // After selected Plan changed
  CREATE_NEW_PLAN_SUCCESS,
  SELECT_PLAN,
  // After saved a Segment
  SAVE_SEGMENT_SUCCESS,
  DELETE_SEGMENT_SUCCESS,
  EDIT_SEGMENT_SUCCESS,
];

// Load Saved Segments Options data
export const SHOULD_LOAD_SEGMENT_OPTIONS = [
  // After Plans loaded
  LOAD_PLANS_SUCCESS,
  // After selected Plan changed
  SELECT_PLAN,
  CREATE_NEW_PLAN_SUCCESS,
  OPEN_FORECAST_SETTINGS_DIALOG,
];

export const SHOULD_LOAD_EVENTS = [
  // After Plans loaded
  LOAD_PLANS_SUCCESS,
  // After selected Plan changed
  SELECT_PLAN,
  CREATE_NEW_PLAN_SUCCESS,
  // After edit/remove event version
  SAVE_EVENT_VERSION_SUCCESS,
  DELETE_EVENT_VERSION_SUCCESS,
  SELECTED_DATE_RANGE_CHANGED,
  SELECTED_SEGMENT_CHANGED,
  LOAD_PLAN_SETTING_SUCCESS,
];

/***************************************
 * Breakdown (KPI)
 **************************************/

export const SHOULD_LOAD_SCENARIO_BREAKDOWNS = [
  // When display Scenarios changed
  LOAD_SCENARIOS_SUCCESS,
  // When select saved segments changed
  SELECTED_SEGMENT_CHANGED,
  // When date range changed
  SELECTED_DATE_RANGE_CHANGED,
  CHANGED_DISPLAYED_DATERANGE_SUCCESS,
];

export const SHOULD_LOAD_CURRENT_BREAKDOWNS = [
  // When selected plan changed
  LOAD_PLANS_SUCCESS,
  SELECT_PLAN,
  CREATE_NEW_PLAN_SUCCESS,
  // When select saved segments changed
  SELECTED_SEGMENT_CHANGED,
  // When date range changed
  SELECTED_DATE_RANGE_CHANGED,
  CHANGED_DISPLAYED_DATERANGE_SUCCESS,
];

export const SHOULD_LOAD_ACTUAL_BREAKDOWNS = [
  // When selected plan changed
  LOAD_PLANS_SUCCESS,
  CREATE_NEW_PLAN_SUCCESS,
  SELECT_PLAN,
  // When select saved segments changed
  SELECTED_SEGMENT_CHANGED,
  // When date range changed
  SELECTED_DATE_RANGE_CHANGED,
  CHANGED_DISPLAYED_DATERANGE_SUCCESS,
];

export const SHOULD_LOAD_COMMITTED_BREAKDOWNS = SHOULD_LOAD_ACTUAL_BREAKDOWNS;

export const SHOULD_LOAD_KPIS_DATA = [
  // When select saved segments changed
  SELECTED_SEGMENT_CHANGED,
  // When date range changed
  SELECTED_DATE_RANGE_CHANGED,
  LOAD_SCENARIOS_SUCCESS,
  // When scenario finished sumulation
  SCENARIO_JUST_FINISHED_SIMULATION,
  LOAD_PLAN_SETTING_SUCCESS,
  CHANGED_DISPLAYED_DATERANGE_SUCCESS,
  SELECT_CURRENCY,
];
export const SHOULD_LOAD_KPIS_CHART_DATA = [
  ...SHOULD_LOAD_KPIS_DATA,
  // When Aggregation change
  UPDATE_SP_CHART_DATE_AGG,
];

export const SHOULD_LOAD_KPIS_MAP_DATA = [
  ...SHOULD_LOAD_KPIS_DATA,
  // When selected scenario changes
  HIGHLIGHT_SCENARIO,
  SELECT_MAP_KPI,
  SELECT_COUNTRY_CODES,
];

export const SHOULD_LOAD_KPIS_MATRIX_DATA = [
  ...SHOULD_LOAD_KPIS_DATA,
  LOAD_PLANS_SUCCESS,
  LOAD_INITIAL_BUSINESS_DRIVER_DATA,
  // When selected scenario changes
  HIGHLIGHT_SCENARIO,
  SELECT_KPI_MATRIX_X_AXIS,
  SELECT_KPI_MATRIX_Y_AXIS,
  SELECT_MATRIX_KPI_1,
  SELECT_MATRIX_KPI_2,
  SELECT_KPI_MATRIX_SORT_KEY,
];

/*************************************************************
 * Demand
 ************************************************************/

export const SHOULD_LOAD_DEMAND_CHART_DATA = [
  // After Plans loaded
  LOAD_PLANS_SUCCESS,
  // When select Plan
  SELECT_PLAN,
  // When select saved segments changed
  SELECTED_SEGMENT_CHANGED,
  // When date range changed
  SELECTED_DATE_RANGE_CHANGED,
  // When grouping changed
  UPDATE_DEMAND_GROUPING,
  // When Aggregation change
  UPDATE_DATE_AGGREGATION,
  UPDATE_UOM,
  // When Actual Plan has been updated
  LOAD_LEGACY_ACTUAL_AND_COMMITTED_SCENARIOS_SUCCESS,
  LOAD_PLAN_SETTING_SUCCESS,
  FORECAST_BASE_TASKS_RAN_SUCCESSFULLY,
  TRIGGER_LOAD_DEMAND,
  LOAD_SCENARIOS_SUCCESS,
];

export const SHOULD_LOAD_DEMAND_IMPACT_CHART_DATA = [
  // When selected Scenarios changed
  LOAD_SCENARIOS_SUCCESS,
  // When Actual & Committed Scenarios changed
  LOAD_LEGACY_ACTUAL_AND_COMMITTED_SCENARIOS_SUCCESS,
  // When selecting events -> load impact
  // SELECT_EVENT_VERSIONS,
  // When show/hide scenario
  UPDATE_DISPLAYED_SCENARIO_SUCCESS,
  // When select saved segments changed
  SELECTED_SEGMENT_CHANGED,
  // When date range changed
  SELECTED_DATE_RANGE_CHANGED,
  // When grouping changed
  UPDATE_DEMAND_GROUPING,
  // When Aggregation change
  UPDATE_DATE_AGGREGATION,
  UPDATE_UOM,
  DEMANDS_SHAPER_TASKS_RAN_SUCCESSFULLY,
  TRIGGER_LOAD_DEMAND_IMPACT
];

export const SHOULD_LOAD_ATOMIC_KPIS_DATA = [
  ...SHOULD_LOAD_KPIS_CHART_DATA,
  SELECT_ATOMIC_KPI,
  UPDATE_KPI_TRACKER_GROUPING,
];

export const SHOULD_LIST_SUPPLY_PLANS = [
  LOAD_PLANS_SUCCESS,
];

export const SHOULD_LOAD_SUPPORTED_CURRENCY = [
  LOAD_PLANS_SUCCESS,
];

export const SHOULD_LOAD_LIST_CURRENCY = [
  LOAD_PLANS_SUCCESS,
  SELECT_CURRENCY,
];

export const SHOULD_LOAD_BUSINESS_DRIVER_DATA = [
  ...SHOULD_LOAD_KPIS_DATA,
  LOAD_PLANS_SUCCESS,
  LOAD_INITIAL_BUSINESS_DRIVER_DATA,
  HIGHLIGHT_SCENARIO,
  SELECT_BUSINESS_DRIVER_MAIN_DIM,
  SELECT_BUSINESS_DRIVER_SECONDARY_DIM,
  SELECT_BUSINESS_DRIVER_MAIN_KPI,
  SELECT_BUSINESS_DRIVER_SECONDARY_KPI,
  SELECT_BUSINESS_DRIVER_SORT_KEY,
];

export const SHOULD_LOAD_SIT_DATA = [
  // After Plans loaded
  // LOAD_PLANS_SUCCESS,
  // When select Plan
  // SELECT_PLAN,
  // When select saved segments changed
  SELECTED_SEGMENT_CHANGED,
  // When date range changed
  SELECTED_DATE_RANGE_CHANGED,
  // When grouping changed
  UPDATE_SIT_GROUPING,
  // When Aggregation change
  UPDATE_SIT_DATE_AGGREGATION,
  UPDATE_SIT_UOM,
  UPDATE_SIT_CONSTRAINED,
  // When Actual Plan has been updated
  LOAD_LEGACY_ACTUAL_AND_COMMITTED_SCENARIOS_SUCCESS,
  FORECAST_BASE_TASKS_RAN_SUCCESSFULLY,
  LOAD_SCENARIOS_SUCCESS,
  ADD_DISPLAYED_SCENARIO_SUCCESS,
  TRIGGER_LOAD_SIT
];

export const SHOULD_LOAD_SIT_MULTI_ACTUAL_X_DATA = [
  LOAD_SIT_MULTI_ACTUAL_X_DATA,
  // Should load after all demand chart data loaded
  // Then the latest raw_demands data is ready to calculate demandsActualX
  LOAD_SIT_DATA_SUCCESS,
  // No need to put in SELECTED_SEGMENT_CHANGED and SELECTED_DATE_RANGE_CHANGED
  // because they are dependencies of LOAD_DEMAND_CHART_DATA_SUCCESS
]


/*************************************************************
 * Tasks
 ************************************************************/

export const SHOULD_FETCH_TASK_STATUSES = [
  LOAD_PLANS_SUCCESS,
  LOAD_SCENARIOS_SUCCESS,
  LOAD_EVENTS_SUCCESS,
];

export const SHOULD_FETCH_FORECAST_BASE_TASKS = [
  START_CHECKING_PENDING_FORECAST_BASE_TASKS
];

export const SHOULD_CHECK_FORECAST_REPORT_TASK = [
  START_CHECKING_FORECAST_REPORT_TASK
];

export const SHOULD_FETCH_DEMANDS_SHAPER_TASKS = [
  START_CHECKING_PENDING_DEMANDS_SHAPER_TASKS,
  // FORECAST_BASE_TASKS_RAN_SUCCESSFULLY
];

export const SHOULD_UPDATE_USER_SETTINGS = [
  UPDATE_USER_SETTINGS,
  UPDATE_DEMAND_ANALYTIC_GROUPING,
  UPDATE_DEMAND_DATASET_YEAR_GROUPING,
  UPDATE_DEMAND_GROUPING,
  UPDATE_KPI_TRACKER_GROUPING,
  UPDATE_KPI_TRACKER_DATASET_YEAR_GROUPING,
  UPDATE_KPI_TRACKER_ANALYTIC_GROUPING,
  SET_DECIMAL_PLACES,
  SET_NUMERIC_SCALE,
  SET_TRADE_TERM_GROUPS,
  SET_SCENARIO_SCORE_METRICS,
]
