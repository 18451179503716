import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PLACEHOLDER_ACTUAL_ID } from '@/app/pages/explorer/shared/explorer.utils';
import {
  select_displayingScenarioIds,
  select_displayingScenarios,
  select_highlightedScenario,
} from '@/store/scenario/scenario.selectors';
import { pick } from 'rambda';
import { endOfMonth, startOfMonth, isBefore, parseISO } from 'date-fns';
import { select_selectedSegment } from '../layout/layout.baseSelectors';
import { IGetKpisParams, IGetSpChartDataParams } from '@/app/@core/services/kpis.service';
import { select_selectedDateRange, select_selectedPlan } from '../layout/layout.selectors';
import { IBizPageState, PAGE__BIZ_STATE_KEY } from './planning-explorer.state';
import {
  createWaterfallSeries,
  createWaterfallSeriesData,
  seriesPairFormatterFactory,
} from '@/app/pages/explorer/planning-explorer/widgets/breakdown/breakdown.utils';
import { ATOMIC_KPIS, BREAKDOWN_KPIS_INFO, calculateCbm, filterAtomicKpis, FINANCE_ATOMIC_KPIS, unitOfKpi } from '@/app/@core/interfaces/business/breakdown';
import { PNL_EVOLUTION_KPIS } from '@/app/pages/explorer/planning-explorer/widgets/breakdown/breakdown.constants';
import { EChartsOption, LineSeriesOption } from 'echarts';
import { seriesPairFormatterFactory_v2 } from '@/app/pages/explorer/planning-explorer/widgets/timeseries/timeseries.utils';
import {
  COLOR_HISTORICAL,
  COLOR_CURRENT,
  DateAggregationOption,
} from '@/app/pages/explorer/planning-explorer/widgets/timeseries/timeseries.constants';
import {
  calculateAverages,
  calculateDataArea,
  calculateY2YDiff,
  findFirstValidNullIndex,
  formatByDateAggregationGen,
  getY2YData,
} from '../demand-planning/demand-planning.utils';
import { transformInputToKpiFormat } from '@/app/pipes/kpi-formatting.pipe';
import { nullAcceptedWithSelectedPlan } from '@/utils/numbers';
import { select_selectedWorkspace } from '@/store/workspace/workspace.selectors';
import { select_latestActual } from '@/store/actual/actual.selectors';
import { IDemandChartResponse, IDemandTreeNode } from '../demand-planning/demand-planning.actions';
import { DemandTableData, select_kpiTrackerAnalyticGroupings } from '../demand-planning/demand-planning.selectors';
import { getScenarioName, getTransformedKpiValue } from '@/app/pages/explorer/planning-explorer/planning-explorer.utils';
import { select_suppyChartParams } from '../supply-explorer/supply-explorer.selectors';
import { truncDim } from '@/app/pages/explorer/planning-explorer/widgets/business-driver/business-driver.utils';

const selectFeature = createFeatureSelector<IBizPageState>(PAGE__BIZ_STATE_KEY);

export const select_spChartDateAgg = createSelector(
  selectFeature,
  (state) => state.spChart_dateAgg,
);
export const select_spChart = createSelector(selectFeature, (state) => state.spChart);
export const select_spChart_loading = createSelector(
  selectFeature,
  (state) => state.spChart_loading,
);
export const select_kpis = createSelector(selectFeature, (state) => state.kpis);
export const select_kpis_loading = createSelector(selectFeature, (state) => state.kpis_loading);
export const select_atomicKpis = createSelector(selectFeature, (state) => state.atomicKpis);
export const select_atomicKpis_loading = createSelector(selectFeature, (state) => state.atomicKpis_loading);
export const select_selectedAtomicKpi = createSelector(selectFeature, (state) => state.selectedAtomicKpi);
export const select_isAtomicKpis = createSelector(selectFeature, (state) => state.isAtomicKpis);
export const select_spChartDecimalPlaces = createSelector(selectFeature, (state) => state.spChartDecimalPlaces);
export const select_spChartNumericScale = createSelector(selectFeature, (state) => state.spChartNumericScale);
export const select_breakdownChartDecimalPlaces = createSelector(selectFeature, (state) => state.breakdownChartDecimalPlaces);
export const select_breakdownChartNumericScale = createSelector(selectFeature, (state) => state.breakdownChartNumericScale);
export const select_supportedCurrencies = createSelector(selectFeature, (state) => state.supportedCurrencies);
export const select_businessDriverData = createSelector(selectFeature, (state) => state.businessDriverData);
export const select_businessDriverData_loading = createSelector(selectFeature, (state) => state.businessDriverData_loading);
export const select_mainDim = createSelector(selectFeature, (state) => state.mainDim);
export const select_secondaryDim = createSelector(selectFeature, (state) => state.secondaryDim);
export const select_mainKPI = createSelector(selectFeature, (state) => state.mainKPI);
export const select_secondaryKPI = createSelector(selectFeature, (state) => state.secondaryKPI);
export const select_sortKey = createSelector(selectFeature, (state) => state.sortKey);
export const select_businessDriverChartDecimalPlaces = createSelector(selectFeature, (state) => state.businessDriverChartDecimalPlaces);
export const select_businessDriverChartNumericScale = createSelector(selectFeature, (state) => state.businessDriverChartNumericScale);
export const select_kpiMatrixXAxis = createSelector(selectFeature, (state) => state.kpiMatrixXAxis);
export const select_kpiMatrixYAxis = createSelector(selectFeature, (state) => state.kpiMatrixYAxis);
export const select_selectedMatrixKpi1 = createSelector(selectFeature, (state) => state.selectedMatrixKpi1);
export const select_selectedMatrixKpi2 = createSelector(selectFeature, (state) => state.selectedMatrixKpi2);
export const select_kpiMatrixSortKey = createSelector(selectFeature, (state) => state.kpiMatrixSortKey);
export const select_selectedMapKpi = createSelector(selectFeature, (state) => state.selectedMapKpi);
export const select_countryCodes = createSelector(selectFeature, (state) => state.countryCodes);
export const select_selectedCountryCodes = createSelector(selectFeature, (state) => state.selectedCountryCodes);
export const select_screenWidth = createSelector(selectFeature, (state) => state.screenWidth);
export const select_scenarioScoreMetrics = createSelector(selectFeature, (state) => state.scenarioScoreMetrics);

export const select_selectedCurrency = createSelector(
  selectFeature,
  select_selectedWorkspace,
  (state, workspace): string => {
    return state.selectedCurrency || workspace?.defaultCurrency;
  },
);
export const select_kpiMatrixData = createSelector(selectFeature, (state) => state.kpiMatrixData);
export const select_kpiMatrix_loading = createSelector(
  selectFeature,
  (state) => state.kpiMatrix_loading,
);
export const select_kpiMapData = createSelector(selectFeature, (state) => state.kpiMapData);
export const select_kpiMap_loading = createSelector(selectFeature, (state) => state.kpiMap_loading);

export const select_breakdownChartOptions = createSelector(
  select_selectedWorkspace,
  select_kpis,
  select_displayingScenarios,
  select_highlightedScenario,
  select_breakdownChartDecimalPlaces,
  select_breakdownChartNumericScale,
  select_screenWidth,
  (workspace, kpis, displayingScenarios, highlighted, decimalPlaces, numericScale, screenWidth): EChartsOption | undefined => {
    if (!kpis) return;
    const numOfScenarios = displayingScenarios.length;
    const hiddenKpis = workspace?.settings?.hiddenKpis || [];
    const displayFinanceKpis = workspace?.settings?.displayFinanceKpis || false;
    const series: any[] = displayingScenarios
      .map((s, scenarioIndex) =>
        createWaterfallSeries(
          s,
          kpis[s.id],
          kpis[PLACEHOLDER_ACTUAL_ID],
          s.color || '#0C80EB',
          s.id === highlighted?.id,
          scenarioIndex,
          numOfScenarios,
          hiddenKpis,
          displayFinanceKpis,
          screenWidth,
        ),
      )
      .reduce((prev, curr) => [...prev, ...curr], []);

    const highlightedSeriesData = createWaterfallSeriesData(
      highlighted,
      kpis[highlighted?.id || ''],
      kpis[PLACEHOLDER_ACTUAL_ID],
      hiddenKpis,
      displayFinanceKpis,
    );
    const tooltipFormatter = seriesPairFormatterFactory(
      highlighted?.name,
      highlightedSeriesData,
      PNL_EVOLUTION_KPIS.map((name) => BREAKDOWN_KPIS_INFO[name]?.type),
      workspace ? workspace.settings?.acceptedScale : [],
      decimalPlaces,
      numericScale,
    );

    return {
      tooltip: { formatter: (p: any) => tooltipFormatter([p]) },
      series: series,
      legend: { show: true }
    };
  },
);

export const select_spChartOptions = createSelector(
  select_spChart,
  select_spChartDateAgg,
  select_displayingScenarios,
  select_highlightedScenario,
  select_selectedWorkspace,
  select_selectedPlan,
  select_latestActual,
  select_spChartDecimalPlaces,
  select_spChartNumericScale,
  select_screenWidth,
  (
    spChartData,
    spChartDateAgg,
    displayingScenarios,
    highlightedScenario,
    workspace,
    selectedPlan,
    latestActual,
    decimalPlaces,
    numericScale,
    screenWidth,
  ): EChartsOption | undefined => {
    if (!spChartData || !selectedPlan || !latestActual) return undefined;

    const marks = calculateDataArea(spChartData);
    const colorLookup = {
      actual: COLOR_HISTORICAL,
      current: COLOR_CURRENT,
      ...Object.fromEntries(displayingScenarios.map((s) => [s.id, s.color || '#0C80EB'])),
    };

    const colFormatter = formatByDateAggregationGen(spChartDateAgg, workspace?.settings?.fiscalYearStartMonth);
    const tooltipFormatter = seriesPairFormatterFactory_v2(
      highlightedScenario?.id,
      workspace,
      true,
      false,
      displayingScenarios,
      workspace ? workspace.settings?.acceptedScale : [],
      colFormatter,
      decimalPlaces,
      numericScale,
    );
    // we will overwrite echarts option
    return {
      tooltip: { formatter: tooltipFormatter, trigger: 'axis', axisPointer: { type: 'shadow' }, backgroundColor: '#d1d1d1' },
      xAxis: {
        axisLabel: { color: '#000', formatter: colFormatter }, data: spChartData.columns,
        type: 'category',
        boundaryGap: false,
        axisLine: { show: false },
        axisTick: { show: false },
      },
      series: spChartData.rows.flatMap(([id, data]) => [
        <LineSeriesOption>{
          id: id + '|NetFIESales',
          name: getScenarioName(id, displayingScenarios) + ' ' + getTransformedKpiValue({ name: 'NetFIESales', label: 'Net Sales Value' }, workspace),
          markArea: marks[id] || marks.future,
          data: data.map((d, index) => d?.DistributorNetSalesValue ? d.DistributorNetSalesValue : nullAcceptedWithSelectedPlan(id, selectedPlan, latestActual, spChartData.columns[index])),
          ...genBasicLineSeriesOptions(colorLookup[id], 'solid', id === highlightedScenario?.id),
          type: 'line'
        },
        <LineSeriesOption>{
          id: id + '|cbm',
          name: getScenarioName(id, displayingScenarios) + ' ' + getTransformedKpiValue({ name: 'cbm', label: 'Profit Before Marketing' }, workspace),
          markArea: marks[id] || marks.future,
          data: data.map((d, index) => calculateCbm(d) ? calculateCbm(d) : nullAcceptedWithSelectedPlan(id, selectedPlan, latestActual, spChartData.columns[index])),
          ...genBasicLineSeriesOptions(colorLookup[id], 'dotted', id === highlightedScenario?.id),
          type: 'line'
        },

      ]),
      title: {
        text: 'Sales & Profit Evolution',
        textStyle: {
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontFamily: 'simcel-Bw-Mitga',
          fontSize: 12,
          lineHeight: 19,
          color: '#686868',
        },
        top: -5
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: (s) => transformInputToKpiFormat(s, '-', workspace ? workspace.settings?.acceptedScale : [], decimalPlaces || 0, numericScale || ''),
          fontFamily: 'simcel-Bw-Mitga',
          align: 'right',
          color: '#000',
          showMinLabel: false,
        },
        axisLine: { show: false },
        axisTick: { show: false },
        splitLine: { show: false },
      },
      textStyle: { color: '#6a6a6a', fontSize: 10 },
      legend: {
        formatter: function (name) {
          const breakpoints = { 1730: 20, 1300: 10, 990: 5, 0: 3 };
          const maxLength = breakpoints[[1730, 1300, 990, 0].find(w => screenWidth > w) ?? 1730]; // Maximum length of legend text
          return name.length > maxLength ? name.slice(0, maxLength) + "..." : name;
        },
        itemHeight: 10,
        itemGap: 15,
        textStyle: { color: '#686868', fontFamily: 'simcel-Bw-Mitga' },
        orient: 'vertical',
        left: 'left',
        top: 'middle',
        padding: 5,
        tooltip:{
          show:true,
          textStyle: { color: '#484848', fontFamily: 'simcel-Bw-Mitga', fontWeight: 'bold' },
        }
      },
      grid: {
        show: true,
        left: '11%',
        right: '0%',
        top: '5%',
        bottom: '0%',
        containLabel: true
      }

    };
  },
);

export const select_params_loadKpisData = createSelector(
  select_selectedPlan,
  select_displayingScenarioIds,
  select_selectedDateRange,
  select_selectedSegment,
  select_spChartDateAgg,
  select_selectedCurrency,
  (
    selectedPlan,
    displayingScenarioIds,
    selectedDateRange,
    selectedSegment,
    spChartDateAgg,
    selectedCurrency,
  ): IGetKpisParams | undefined => {
    if (!selectedPlan) return;

    const scenariosToExclude = ['baseDemand'];

    return {
      planId: selectedPlan.id,
      scenarios: displayingScenarioIds
        .filter(id => !scenariosToExclude.includes(id))
        .filter(id => !id?.includes('actual-')), // getSpChart doesn't work with 'current' and 'baseDemand' scenarioId in params, should fix?
      dateRange: selectedDateRange,
      segment: selectedSegment,
      interval: DateAggregationOption.MONTH, // spChartDateAgg, // Should use Month interval to make KPI table consistent with KPI Tracker and Business Driver
      kpisByDate: false,
      currency: selectedCurrency,
    };
  },
);
export const select_params_loadSpChartData = createSelector(
  select_params_loadKpisData,
  select_spChartDateAgg,
  (params_loadKpisData, spChartDateAgg): IGetSpChartDataParams | undefined => {
    if (!params_loadKpisData) return;
    return {
      ...params_loadKpisData,
      interval: spChartDateAgg,
      kpisByDate: true,
    };
  },
);

export function genBasicLineSeriesOptions(
  color: string,
  type: 'solid' | 'dotted',
  isHighlight: boolean,
): LineSeriesOption {
  // use straight lines instead of curved lines
  return {
    type: 'line',
    // smooth: true,
    symbol: 'circle',
    showAllSymbol: true,
    itemStyle: { borderWidth: 1, borderColor: color, color },
    lineStyle: { width: isHighlight ? 4 : 1, type, color },
    emphasis: { disabled: true },
    symbolSize: 8,
  };
}

export const select_combinedKpiTrackerData = createSelector(
  select_selectedPlan,
  select_latestActual,
  select_displayingScenarios,
  select_atomicKpis,
  (selectedPlan, latestActual, displayingScenarios, atomicKpis): IDemandChartResponse => {
    if (!selectedPlan || !latestActual) {
      return { columns: [], rows: [], tree: [] };
    }

    const scenarios = displayingScenarios.map(pick(['id', 'name']));
    const initialHasActualId = scenarios.some((s) => s.id === 'actual');
    const initialHasCurrentId = scenarios.some((s) => s.id === 'current');
    // If either initialHasActualId or initialHasCurrentId is true, incorporate them into the tree for ForecastBase calculation via table.mergeHistoricalData().
    // Subsequently (in select_combinedDemandsTableDataWithExtraColumns), remove actual or current from the tree if they aren't present in displayingScenarios,
    // as they are not intended to be displayed in the demand table.
    if (!initialHasActualId) {
      scenarios.push({ id: 'actual', name: 'Actual' });
    }
    if (!initialHasCurrentId) {
      scenarios.push({ id: 'current', name: 'Last Plan' });
    }

    const rows: IDemandChartResponse['rows'] = scenarios.map((s) => {
      const c = (atomicKpis?.rows || []).find(a => a[0] === s.id) ?? [];
      return c;
    });

    const columns: string[] = atomicKpis?.columns || [];
    const tree: IDemandChartResponse['tree'] = scenarios.map((s) => {
      const c = (atomicKpis?.tree || []).find(a => a.key === s.id) ?? {};
      return <IDemandTreeNode>{
        ...c,
        key: s.id,
        label: s.name,
      };
    });

    const actualRow = rows.find(row => row[0] === "actual")?.[1] || [];
    const currentRow = rows.find(row => row[0] === "current")?.[1] || [];
    const planStartDate = parseISO(selectedPlan?.defaultPlanDisplayStartDate || '');
    const displayedStartDate = parseISO(columns[0]);
    const firstNullIndex = findFirstValidNullIndex(actualRow);

    const actualLastDefinedIndex = firstNullIndex >= 0 ? firstNullIndex - 1 : actualRow.length - 1;
    const currentFirstDefinedIndex = currentRow.findIndex(x => x != null);

    const hasGapBeforeStartOfFuture = actualLastDefinedIndex === -1 && isBefore(displayedStartDate, planStartDate);
    const hasGapBetweenActualCurrent = actualLastDefinedIndex > -1 && actualLastDefinedIndex < currentFirstDefinedIndex;

    const fillingData = {
      actualColumns: columns.slice(0, actualLastDefinedIndex + 1),
      lastPlanColumns: currentFirstDefinedIndex === -1 ? [] : columns.slice(currentFirstDefinedIndex),
      hasGapBeforeStartOfFuture,
      hasGapBetweenActualCurrent
    };

    const actualEndDate = latestActual?.actualEndDate;
    const actualEndDateIndex = columns.findIndex(date => date.substring(0, 7) === ((actualEndDate as unknown) as string).substring(0, 7));
    const lastYearData = actualRow.filter((_, i) => i <= actualEndDateIndex).slice(-12);
    const runRates = calculateAverages(lastYearData);

    return { columns, rows, tree, fillingData, runRates };
  },
);

export const select_combinedKpiTrackerDataWithExtraColumns = createSelector(
  select_kpiTrackerAnalyticGroupings,
  select_combinedKpiTrackerData,
  select_highlightedScenario,
  select_selectedWorkspace,
  select_selectedPlan,
  select_latestActual,
  select_displayingScenarios,
  select_selectedAtomicKpi,
  (analyticGroupings, data, highlightedScenario, workspace, selectedPlan, latestActual, displayingScenarios, selectedAtomicKpi) => {
    let table = new DemandTableData(data);
    if (data.columns.length < 1) {
      return table;
    }

    table.sort();
    table.mergeHistoricalData();

    const scenarios = displayingScenarios.map(pick(['id', 'name']));
    const initialHasActualId = scenarios.some((s) => s.id === 'actual');
    const initialHasCurrentId = scenarios.some((s) => s.id === 'current');
    // Remove actual or current from the tree if they aren't present in displayingScenarios,
    // as they are not intended to be displayed in the demand table.
    if (!initialHasActualId) {
      table.removeTableRow('actual');
    }
    if (!initialHasCurrentId) {
      table.removeTableRow('current');
    }

    const fiscalYearStartMonth = workspace.settings?.fiscalYearStartMonth || 1;
    const analyticColumnParams = {
      analyticGroupings,
      actualEndDate: endOfMonth(new Date(latestActual?.actualEndDate)),
      futurePlanStartDate: startOfMonth(new Date(selectedPlan?.futurePlanStartDate || '')),
      futurePlanEndDate: endOfMonth(new Date(selectedPlan?.futurePlanEndDate || '')),
      selectedAtomicKpi,
      fiscalYearStartMonth,
    };
    table.addAnalyticColumns(analyticColumnParams);
    // Should calculate y2yData after addAnalyticColumns() to get full columns with analytics
    table.y2yData = getY2YData(table.columns, analyticGroupings, fiscalYearStartMonth);

    if (highlightedScenario) {
      const key = highlightedScenario.id;
      const highlightedRow = table.tree.find(t => t.key == key);
      if (highlightedRow) {
        table.compare(highlightedRow);
      }
    }

    table.tree.forEach(row => {
      // calculate y2y comparison for all table parent rows
      // pass row by reference, therefore mutate the table's row directly
      calculateY2YDiff(row, table.y2yData!);
    })

    return table;
  }
)

export const select_businessDriverChartOptions = createSelector(
  select_selectedWorkspace,
  select_businessDriverData,
  select_suppyChartParams,
  select_selectedCurrency,
  select_businessDriverChartDecimalPlaces,
  select_businessDriverChartNumericScale,
  select_screenWidth,
  (
    selectedWorkspace,
    businessDriverData,
    commonParams,
    selectedCurrency,
    decimalPlaces,
    numericScale,
    screenWidth,
  ): EChartsOption | undefined => {
    if (!businessDriverData || businessDriverData.length === 0) {
      return;
    }

    const selectedMainKPI = Object.keys(businessDriverData[0])[1];
    const selectedSecondaryKPI = Object.keys(businessDriverData[0])[2];
    
    const kpis = filterAtomicKpis(ATOMIC_KPIS, selectedWorkspace, FINANCE_ATOMIC_KPIS);
    const foundMainKPI = kpis.find((kpi) => kpi.name === selectedMainKPI);
    const foundSecondaryKPI = kpis.find((kpi) => kpi.name === selectedSecondaryKPI);
    const mainKPI = (foundMainKPI ? getTransformedKpiValue(foundMainKPI, selectedWorkspace) : '') + unitOfKpi(selectedMainKPI, selectedWorkspace!, selectedCurrency) || selectedMainKPI;
    const secondaryKPI = (foundSecondaryKPI ? getTransformedKpiValue(foundSecondaryKPI, selectedWorkspace) : '') + unitOfKpi(selectedSecondaryKPI, selectedWorkspace!, selectedCurrency) || selectedSecondaryKPI;

    const mainData = businessDriverData?.map((data) => {
      return (data[selectedMainKPI] || 0) as number;
    });
    const secondaryData = businessDriverData?.map((data) => {
      return (data[selectedSecondaryKPI] || 0) as number;
    });
    const dimension = businessDriverData?.map((i) => i.Dimension);

    return {
      legend: {
        data: [
          mainKPI,
          secondaryKPI,
        ]
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        valueFormatter: (s: any) => transformInputToKpiFormat(parseFloat(s), '-', [], decimalPlaces || 0, numericScale || ''),
      },
      xAxis: [
        {
          type: 'category',
          axisLabel: {
            color: '#000',
            interval: 0,
            rotate: 20,
            fontSize: 12,
            formatter: function (value) {
              return truncDim(value);
            },
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          data: dimension,
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: mainKPI,
          axisPointer: {
            show: false,
          },
          axisLabel: {
            color: '#000',
            fontSize: 12,
            rotate: screenWidth > 1730 ? 0 : 20,
            formatter: (s) => transformInputToKpiFormat(s, '-', [], decimalPlaces || 0, numericScale || ''),
          },
        },
        {
          type: 'value',
          name: secondaryKPI,
          axisPointer: {
            show: false,
          },
          axisLabel: {
            color: '#000',
            formatter: (s) => transformInputToKpiFormat(s, '-', [], decimalPlaces || 0, numericScale || ''),
          },
        }
      ],
      series: [
        {
          type: 'bar',
          name: mainKPI,
          data: mainData,
          itemStyle: {
            color: (commonParams?.highlightedScenario?.color || '#0C80EB') + '1A',
            borderColor: commonParams?.highlightedScenario?.color || '#0C80EB',
          },
        },
        {
          type: 'line',
          name: secondaryKPI,
          data: secondaryData,
          yAxisIndex: 1,
          smooth: true,
          symbol: 'circle',
          lineStyle: {
            color: commonParams?.highlightedScenario?.color || '#0C80EB',
            width: 2,
          },
          itemStyle: {
            borderWidth: 1,
            borderColor: commonParams?.highlightedScenario?.color || '#0C80EB',
            color: (commonParams?.highlightedScenario?.color) || '#0C80EB' + '3A'
          }
        }
      ]
    };
  },
);
