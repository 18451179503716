import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Doc } from 'yjs';
import { SocketIOProvider } from 'y-socket.io';
import { HttpService } from '../backend/common/api/http.service';

export type BaseUser = {
  name: string;
  joinedOn: number;
};
export type EditingSitScenario = {
  id: string;
  timestamp: number;
};
export type Position = { top: number; left: number };
export type CursorProps = {
  newPosition: Position;
  workspaceId?: string;
  editingScenarioId?: string;
  editingEventId?: string;
  editingVersionId?: string;
  editingSitScenarios: EditingSitScenario[];
  simulatingScenarioIds: string[];
}
export type LocalUser = BaseUser & CursorProps;
export type RemoteUser = BaseUser & CursorProps;

@Injectable()
export class CrdtService {
  private awarenessChangeSubject = new Subject<{ changes: unknown[], event: "local" | Record<string, unknown> }>();
  awarenessChange$ = this.awarenessChangeSubject.asObservable();

  private localUserSubject = new BehaviorSubject<LocalUser | null>(null);
  localUser$ = this.localUserSubject.asObservable(); // Other components can subscribe to this

  private remoteUsersSubject = new BehaviorSubject<RemoteUser[]>([]);
  remoteUsers$ = this.remoteUsersSubject.asObservable(); // Other components can subscribe to this
  
  constructor(private httpService: HttpService) {}

  usersFromAwareness(awareness: SocketIOProvider["awareness"]) {
    const { clientID } = awareness;
    return {
      getLocalUser: () => {
        const local = awareness.getLocalState();
        return local?.name ? (local as LocalUser) : null;
      },
      getRemoteUsers: () =>
        Array.from(awareness.getStates().entries())
          .flatMap(([key, value]) =>
            key !== clientID && value.name ? [value as RemoteUser] : []
          )
          .sort((a, b) => a.joinedOn - b.joinedOn),
    };
  }
  
  createAwarenessUsers({ awareness }: SocketIOProvider) {
    const { getLocalUser, getRemoteUsers } = this.usersFromAwareness(awareness);
    const handler = (changes: unknown[], event: "local" | Record<string, unknown>) => {
      this.awarenessChangeSubject.next({ changes, event });

      const localUser = getLocalUser();
      this.localUserSubject.next(localUser);

      const remoteUsers = getRemoteUsers();
      this.remoteUsersSubject.next(remoteUsers);
    };

    awareness.on("change", handler);

    return {
      getLocalUser,
      getRemoteUsers,
      handleCursorPositionChange(cursorProps: CursorProps) {
        const {
          newPosition,
          workspaceId,
          editingScenarioId,
          editingEventId,
          editingVersionId,
          editingSitScenarios,
          simulatingScenarioIds,
        } = cursorProps;
        awareness.setLocalStateField("newPosition", newPosition);
        awareness.setLocalStateField("workspaceId", workspaceId);
        awareness.setLocalStateField("editingScenarioId", editingScenarioId);
        awareness.setLocalStateField("editingEventId", editingEventId);
        awareness.setLocalStateField("editingVersionId", editingVersionId);
        awareness.setLocalStateField("editingSitScenarios", editingSitScenarios);
        awareness.setLocalStateField("simulatingScenarioIds", simulatingScenarioIds);
      },
      handleLogin(name: string) {
        awareness.setLocalState({
          ...awareness.getLocalState(),
          name,
          joinedOn: Date.now(),
        });
      },
      cleanup: () => {
        awareness.off("change", handler);
      }
    };
  }
  
  createCrdtState() {
    const doc = new Doc();
    const apiUrl = this.httpService.apiUrl;
    const wsUrl = apiUrl.replace(/(https?:\/\/)/, apiUrl.startsWith('https://') ? 'wss://' : 'ws://').replace('/api', ''); 
    
    const socketIOProvider = new SocketIOProvider(
      wsUrl,
      'crdt_documents',
      doc,
      {
        autoConnect: true,
      }
    );
    
    socketIOProvider.awareness.on('change', () => {
      const clients = Array.from(socketIOProvider.awareness.getStates().entries());
      // console.log({ clients });
    });

    socketIOProvider.awareness.setLocalState({ id: Math.random(), name: 'Perico' });

    socketIOProvider.on('sync', (isSync: boolean) => {
      // console.log('websocket sync', isSync);
    });

    socketIOProvider.on('status', ({ status: _status }: { status: string }) => {
      // if (!!_status) console.log({ status: _status });
    });

    const { getLocalUser, getRemoteUsers, handleLogin, handleCursorPositionChange, cleanup } =
      this.createAwarenessUsers(socketIOProvider);

    return {
      getLocalUser,
      getRemoteUsers,
      handleLogin,
      moveCursor(cursorProps: CursorProps) { 
        handleCursorPositionChange(cursorProps);
      },
      cleanup,
    };
  }
}
