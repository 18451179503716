import { createAction, props } from '@ngrx/store';

export const LOAD_FORECAST_EXPLORER_METRICS = createAction(
  '[🤖 Forecast Explorer Page] Load Metrics',
  props<{ params: any }>(),
);
export const LOAD_FORECAST_EXPLORER_METRICS_SUCCESS = createAction(
  '[🤖 Forecast Explorer Page] Load Metrics success',
  props<{ data: any }>(),
);
export const LOAD_FORECAST_EXPLORER_METRICS_FAILED = createAction(
  '[🤖 Forecast Explorer Page] Load Metrics failed',
  props<{ error: any }>(),
);
export const LOAD_FORECAST_EXPLORER_ALL_DATA = createAction(
  '[🤖 Forecast Explorer Page] Load All Data',
  props<{ params: any }>(),
);
export const LOAD_FORECAST_EXPLORER_ALL_DATA_SUCCESS = createAction(
  '[🤖 Forecast Explorer Page] Load All Data success',
  props<{ data: any }>(),
);
export const LOAD_FORECAST_EXPLORER_ALL_DATA_FAILED = createAction(
  '[🤖 Forecast Explorer Page] Load All Data failed',
  props<{ error: any }>(),
);
export const LOAD_FORECAST_EXPLORER_TIME_SERIES_DATA = createAction(
  '[🤖 Forecast Explorer Page] Load Time Series Data',
  props<{ params: any }>(),
);
export const LOAD_FORECAST_EXPLORER_TIME_SERIES_DATA_SUCCESS = createAction(
  '[🤖 Forecast Explorer Page] Load Time Series Data success',
  props<{ data: any }>(),
);
export const LOAD_FORECAST_EXPLORER_TIME_SERIES_DATA_FAILED = createAction(
  '[🤖 Forecast Explorer Page] Load Time Series Data failed',
  props<{ error: any }>(),
);
export const LOAD_FORECAST_EXPLORER_ALL_DATA_LENGTH = createAction(
  '[🤖 Forecast Explorer Page] Load All Data Length',
  props<{ params: any }>(),
);
export const LOAD_FORECAST_EXPLORER_ALL_DATA_LENGTH_SUCCESS = createAction(
  '[🤖 Forecast Explorer Page] Load All Data Length success',
  props<{ data: any }>(),
);
export const LOAD_FORECAST_EXPLORER_ALL_DATA_LENGTH_FAILED = createAction(
  '[🤖 Forecast Explorer Page] Load All Data Length failed',
  props<{ error: any }>(),
);
export const LOAD_FORECAST_EXPLORER_HEATMAP_DATA = createAction(
  '[🤖 Forecast Explorer Page] Load Heatmap Data',
  props<{ params: any }>(),
);
export const LOAD_FORECAST_EXPLORER_HEATMAP_DATA_SUCCESS = createAction(
  '[🤖 Forecast Explorer Page] Load Heatmap Data success',
  props<{ data: any }>(),
);
export const LOAD_FORECAST_EXPLORER_HEATMAP_DATA_FAILED = createAction(
  '[🤖 Forecast Explorer Page] Load Heatmap Data failed',
  props<{ error: any }>(),
);
export const LOAD_FORECAST_EXPLORER_HEATMAP_DATA_LENGTH = createAction(
  '[🤖 Forecast Explorer Page] Load Heatmap Data Length',
  props<{ params: any }>(),
);
export const LOAD_FORECAST_EXPLORER_HEATMAP_DATA_LENGTH_SUCCESS = createAction(
  '[🤖 Forecast Explorer Page] Load Heatmap Data Length success',
  props<{ data: any }>(),
);
export const LOAD_FORECAST_EXPLORER_HEATMAP_DATA_LENGTH_FAILED = createAction(
  '[🤖 Forecast Explorer Page] Load Heatmap Data Length failed',
  props<{ error: any }>(),
);
