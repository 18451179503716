import { DateAggregationOption } from '@/app/pages/explorer/planning-explorer/widgets/timeseries/timeseries.constants';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpService } from '../backend/common/api/http.service';
import { Segment } from '../interfaces/business/segment';
import { CustomerGranularity, ProductGranularity } from '../interfaces/business/forecast-explorer';
import { SimpleDateRange } from '../interfaces/common/date-range';

export interface IFilterInput {
  master_database_name: string;
  segment: Segment;
}

export interface IForecastExplorerParams {
  database_name: string;
  product_granularity: ProductGranularity;
  customer_granularity: CustomerGranularity;
  time_granularity: DateAggregationOption;
  collection_name: string;
  filter_input: IFilterInput;
  limit?: number;
  skip?: number;
  start_date?: string;
  end_date?: string;
  workspace_id: string;
}

export interface ICalculateForecastExplorerMetricsParams {
  dateRange: SimpleDateRange;
  forecastCollection: string;
  workspaceId: string;
}

export interface IRenderForecastReportParams {
  analysisDateRange: SimpleDateRange;
  workspaceId: string;
  evaluateDateRange: SimpleDateRange;
  timeAggregation: string;
  segmentGroup: string;
  uom: string;
  outlierCoefficient: number;
  numberTopContibutors: number;
  percentageContibution: number;
  channels: string[];
}

@Injectable()
export class ForecastExplorerService {
  // Subject to emit when we need to rerun ngOnInit
  private _reload = new Subject<void>();
  // Observable to which the child component will subscribe
  public reload$ = this._reload.asObservable();

  constructor(private readonly httpService: HttpService) {}

  getMetrics(params: IForecastExplorerParams) {
    return this.httpService.postDataScienceAPI('forecast-explorer/metrics', params);
  }

  getMetaData(path: string) {
    return this.httpService.getDataScienceAPI(`forecast-explorer/metadata/${path}`);
  }

  getAllData(params: IForecastExplorerParams) {
    return this.httpService.postDataScienceAPI('forecast-explorer/all-data', params);
  }

  getHeatmapData(params: IForecastExplorerParams) {
    return this.httpService.postDataScienceAPI('forecast-explorer/heatmap', params);
  }

  calculateForecastExplorerMetrics(params: ICalculateForecastExplorerMetricsParams) {
    return this.httpService.post('demands/prepare-forecast', params);
  }

  renderForecastReport(params: IRenderForecastReportParams) {
    return this.httpService.post('demands/render-forecast-report', params);
  }

  fetchPendingForecastExplorerTasks() {
    return this.httpService.get(`simcel-async/demand-generation/pending-forecast-explorer-tasks`);
  }

  fetchPendingForecastReportTasks() {
    return this.httpService.get(`simcel-async/demand-generation/pending-forecast-report-tasks`);
  }

  checkForecastReportTask(taskId: string) {
    return this.httpService.get(
      `simcel-async/demand-generation/check-forecast-report-task?taskId=${taskId}`,
    );
  }

  triggerReload() {
    this._reload.next();
  }
}
