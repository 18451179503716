import { BusinessDriverData, IGetBusinessDriverParams } from '@/app/@core/services/business-driver.service';
import { IGetAtomicKpisParams, IGetKpisParams, IGetSpChartDataParams } from '@/app/@core/services/kpis.service';
import { DateAggregationOption } from '@/app/pages/explorer/planning-explorer/widgets/timeseries/timeseries.constants';
import { createAction, props } from '@ngrx/store';
import { ScenarioScoreMetric } from './planning-explorer.state';

export const ENSURE_NO_SCENARIO_IS_EDITING = createAction(
  '[🤖 Planning Explorer Page] Ensure no Scenario is editing',
);

export const LOAD_KPIS_DATA = createAction(
  '[⚡ Biz Page] Load Breakdowns data',
  props<{ params: IGetKpisParams }>(),
);

export const LOAD_KPIS_DATA_SUCCESS = createAction(
  '[🤖 Biz Page] Load Breakdowns data success',
  props<{ data: any }>(),
);

export const LOAD_KPIS_DATA_FAILED = createAction(
  '[🤖 Biz Page] Load Breakdowns data failed',
  props<{ error: any }>(),
);

export const UPDATE_KPIS_LOADED_PARAMS = createAction(
  '[🤖 Biz Page] Update Kpis loaded params',
  props<{ params: IGetKpisParams }>(),
)

export const LOAD_SP_CHART_DATA = createAction(
  '[⚡ Biz Page] Load "Sale & Profit" Chart data',
  props<{ params: IGetSpChartDataParams }>(),
);

export const LOAD_SP_CHART_DATA_SUCCESS = createAction(
  '[🤖 Biz Page] Load "Sale & Profit" Chart data success',
  props<{ data: any }>(),
);

export const LOAD_SP_CHART_DATA_FAILED = createAction(
  '[🤖 Biz Page] Load "Sale & Profit" Chart data failed',
  props<{ error: any }>(),
);
export const UPDATE_SP_CHART_DATE_AGG = createAction(
  '[🌱 Biz Page] Update "Sale & Profit" Chart Aggregate Option',
  props<{ option: DateAggregationOption }>(),
);

export const LOAD_ATOMIC_KPIS_DATA = createAction(
  '[⚡ Biz Page] Load Atomic KPIs data',
  props<{ params: IGetAtomicKpisParams }>(),
);

export const LOAD_ATOMIC_KPIS_DATA_SUCCESS = createAction(
  '[🤖 Biz Page] Load Atomic KPIs data success',
  props<{ data: any }>(),
);

export const LOAD_COUNTRY_CODES = createAction(
  '[🤖 Biz Page] Load Country Codes',
  props<{ countryCodes: string[] }>(),
);

export const SELECT_COUNTRY_CODES = createAction(
  '[🤖 Biz Page] Select Country Codes',
  props<{ selectedCountryCodes: string[] }>(),
);

export const LOAD_ATOMIC_KPIS_DATA_FAILED = createAction(
  '[🤖 Biz Page] Load Atomic KPIs data failed',
  props<{ error: any }>(),
);

export const SELECT_ATOMIC_KPI = createAction(
  '[🤖 Biz Page] Select Atomic KPI',
  props<{ selectedAtomicKpi: string }>(),
);

export const SELECT_MAP_KPI = createAction(
  '[🤖 Biz Page] Select Map KPI',
  props<{ selectedMapKpi: string }>(),
);

export const SELECT_MATRIX_KPI_1 = createAction(
  '[🤖 Biz Page] Select Matrix KPI 1',
  props<{ selectedMatrixKpi1: string }>(),
);

export const SELECT_MATRIX_KPI_2 = createAction(
  '[🤖 Biz Page] Select Matrix KPI 2',
  props<{ selectedMatrixKpi2: string }>(),
);

export const SET_SPCHART_DECIMAL_PLACES = createAction(
  '[🤖 Biz Page] Set SpChart Decimal Places',
  props<{ spChartDecimalPlaces: number }>(),
);
export const SET_SPCHART_NUMERIC_SCALE = createAction(
  '[🤖 Biz Page] Set SpChart Numeric Scale',
  props<{ spChartNumericScale: string }>(),
);
export const SET_BREAK_DOWN_CHART_DECIMAL_PLACES = createAction(
  '[🤖 Biz Page] Set Break Down Chart Decimal Places',
  props<{ breakdownChartDecimalPlaces: number }>(),
);
export const SET_BREAK_DOWN_CHART_NUMERIC_SCALE = createAction(
  '[🤖 Biz Page] Set Break Down Chart Numeric Scale',
  props<{ breakdownChartNumericScale: string }>(),
);

export const SELECT_CURRENCY = createAction(
  '[🤖 Biz Page] Select Currency',
  props<{ selectedCurrency: string }>(),
);

export const LOAD_SUPPORTED_CURRENCIES = createAction(
  '[⚡ Biz Page] Load Supported Currencies',
);

export const LOAD_SUPPORTED_CURRENCIES_SUCCESS = createAction(
  '[🤖 Biz Page] Load Supported Currencies success',
  props<{ data: string[] }>(),
);

export const LOAD_SUPPORTED_CURRENCIES_FAILED = createAction(
  '[🤖 Biz Page] Load Supported Currencies failed',
  props<{ error: any }>(),
);

export const LOAD_INITIAL_BUSINESS_DRIVER_DATA = createAction(
  '[🤖 Plan API] Load Initial Business Driver data',
);

export const LOAD_BUSINESS_DRIVER_DATA = createAction(
  '[⚡ Biz Page] Load Business Driver data',
  props<{ params: IGetBusinessDriverParams }>(),
);

export const LOAD_BUSINESS_DRIVER_DATA_SUCCESS = createAction(
  '[🤖 Biz Page] Load Business Driver data success',
  props<{ data: BusinessDriverData[] }>(),
);

export const LOAD_BUSINESS_DRIVER_DATA_FAILED = createAction(
  '[🤖 Biz Page] Load Business Driver data failed',
  props<{ error: any }>(),
);

export const SELECT_BUSINESS_DRIVER_MAIN_DIM = createAction(
  '[🤖 Biz Page] Select Business Driver Main Dim',
  props<{ mainDim: string }>(),
);

export const SELECT_BUSINESS_DRIVER_SECONDARY_DIM = createAction(
  '[🤖 Biz Page] Select Business Driver Secondary Dim',
  props<{ secondaryDim: string }>(),
);

export const SELECT_BUSINESS_DRIVER_MAIN_KPI = createAction(
  '[🤖 Biz Page] Select Business Driver Main KPI',
  props<{ mainKPI: string }>(),
);

export const SELECT_BUSINESS_DRIVER_SECONDARY_KPI = createAction(
  '[🤖 Biz Page] Select Business Driver Secondary KPI',
  props<{ secondaryKPI: string }>(),
);

export const SELECT_BUSINESS_DRIVER_SORT_KEY = createAction(
  '[🤖 Biz Page] Select Business Driver Sort Key',
  props<{ sortKey: number }>(),
);

export const SET_BUSINESS_DRIVER_DECIMAL_PLACES = createAction(
  '[🤖 Biz Page] Set Business Driver Decimal Places',
  props<{ businessDriverChartDecimalPlaces: number }>(),
);
export const SET_BUSINESS_DRIVER_NUMERIC_SCALE = createAction(
  '[🤖 Biz Page] Set Business Driver Numeric Scale',
  props<{ businessDriverChartNumericScale: string }>(),
);

export const LOAD_KPI_MATRIX_DATA = createAction(
  '[⚡ Biz Page] Load KPI Matrix data',
  props<{ params: any }>(),
);

export const LOAD_KPI_MATRIX_DATA_SUCCESS = createAction(
  '[🤖 Biz Page] Load KPI Matrix data success',
  props<{ data: any }>(),
);

export const LOAD_KPI_MATRIX_DATA_FAILED = createAction(
  '[🤖 Biz Page] Load KPI Matrix data failed',
  props<{ error: any }>(),
);

export const LOAD_KPI_MAP_DATA = createAction(
  '[⚡ Biz Page] Load KPI Map data',
  props<{ params: any }>(),
);

export const LOAD_KPI_MAP_DATA_SUCCESS = createAction(
  '[🤖 Biz Page] Load KPI Map data success',
  props<{ data: any }>(),
);

export const LOAD_KPI_MAP_DATA_FAILED = createAction(
  '[🤖 Biz Page] Load KPI Map data failed',
  props<{ error: any }>(),
);

export const SELECT_KPI_MATRIX_X_AXIS = createAction(
  '[🤖 Biz Page] Select KPI Matrix X Axis',
  props<{ kpiMatrixXAxis: string }>(),
);

export const SELECT_KPI_MATRIX_Y_AXIS = createAction(
  '[🤖 Biz Page] Select KPI Matrix Y Axis',
  props<{ kpiMatrixYAxis: string }>(),
);
export const SELECT_KPI_MATRIX_SORT_KEY = createAction(
  '[🤖 Biz Page] Select KPI Matrix Sort Key',
  props<{ sortKey: number }>(),
);

export const SET_SCREEN_WIDTH = createAction(
  '[🤖 Biz Page] Set Screen Width',
  props<{ screenWidth: number }>(),
); 

export const SET_SCENARIO_SCORE_METRICS = createAction(
  '[🤖 Biz Page] Set Scenario Score Metrics',
  props<{ scenarioScoreMetrics: ScenarioScoreMetric[] }>(),
);
